import { Contract, OfferDetail, Participant, Property } from "../../generated";
import { getOffers, initialise } from "./thunks";
import { createAppSlice } from "../../app/createAppSlice";

export enum ContractStatus {
  initial,
  loading,
  ready,
}
interface ContractState {
  status: ContractStatus;
  contract: Contract | undefined;
  contractParticipants: Array<Participant>;
  property: Property;
  newOfferId: number | undefined;
  offers: Array<OfferDetail>;
}

const initialState: ContractState = {
  status: ContractStatus.initial,
  contract: undefined,
  newOfferId: undefined,
  property: {  },
  contractParticipants: [],
  offers: [],
};
export const contractSlice = createAppSlice({
  name: "contract",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initialise.fulfilled, (state, action) => {
      state.contract = action.payload;
    });

    builder.addCase(getOffers.fulfilled, (state, action) => {
      state.offers = action.payload.offers;
      state.contractParticipants = action.payload.contractParticipants;

      state.offers.sort((a, b) => {
        const d1 = new Date(a.offer.createdAt!).getTime();
        const d2 = new Date(b.offer.createdAt!).getTime();
        return d2 - d1;
      });
      state.status = ContractStatus.ready;
    });
  },
  selectors: {
    selectContractState: state => state,
  }
});

export const {selectContractState} = contractSlice.selectors;