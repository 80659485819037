import { createAsyncThunk } from "@reduxjs/toolkit";
import { contractsApi, offersApi, propertyApi } from "../../api";
import axios from "axios";
import { User } from "oidc-client-ts";

import { Contract, Participant, Property } from "../../landconnex-api-client";
import { appConfig } from "../../constants";

export interface WorkspaceOfferArgs {
  workspaceId: number;
  offer: Contract;
}

export interface GetPDFArgs {
  workspaceId: number;
  offerId: number;
}
export const getDraft = createAsyncThunk<string, WorkspaceOfferArgs>(
  "offerDraft/getDraft",
  async ({ workspaceId, offer }) => {
    const response = await offersApi.getOfferDraft(workspaceId, offer.id!);

    return response.data;
  }
);

export interface GetPDFResponse {
  url: string;
  contract: Contract;
  property: Property;
  offer: Contract;
  participants: Array<Participant>;
}
export const getPDF = createAsyncThunk<GetPDFResponse, GetPDFArgs>(
  "offerDraft/getPDF",
  async ({ workspaceId, offerId }) => {
    const oidcStorage = sessionStorage.getItem(
      `oidc.user:${appConfig.authority}:${appConfig.clientId}`
    );
    const user = User.fromStorageString(oidcStorage!);

    const response = await axios.get(
      `${appConfig.workspacesUri}/workspaces/${workspaceId}/offers/${offerId}/pdf`,
      {
        headers: { Authorization: `Bearer ${user.access_token}` },
        responseType: "blob",
      }
    );
    
    const promiseProperty = propertyApi.getProperty(workspaceId);
    const promiseOffer = offersApi.getOffer(workspaceId, offerId);
    const promiseParticipants = offersApi.getOfferParticipants(workspaceId, offerId);
    const promiseContract = contractsApi.getContract(workspaceId);

    const [property, offer, participants, contract] = await Promise.all([promiseProperty, promiseOffer, promiseParticipants, promiseContract]);

    const url = window.URL.createObjectURL(response.data);
    const resp: GetPDFResponse = {
      url:url,
      contract: contract.data,
      property: property.data,
      participants: participants.data.items!,
      offer: offer.data
    };
    return resp;
  }
);

export const saveName = createAsyncThunk<void, WorkspaceOfferArgs>(
  "offerDraft/saveName",
  async ({ offer, workspaceId }) => {
    offersApi.putOffer(workspaceId, offer.id!, offer);
  }
);
