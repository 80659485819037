const authority = (window as any)["AUTHORITY"]
const clientId = (window as any)["CLIENT_ID"]
const redirectUri = (window as any)["REDIRECT_URI"]
const workspacesUri = (window as any)["KONVEI_MATTERS_URI"]
const eventsUri = (window as any)["EVENTS_URI"]
const conveyancingDocumentsApi = (window as any)[
  "KONVEI_CONVEYANCING_DOCUMENTS_URI"
]

export const appConfig = {
  authority,
  clientId,
  workspacesUri,
  conveyancingDocumentsApi,
  redirectUri,
  eventsUri,
}
