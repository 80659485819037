import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  ActivityLog,
  DocumentSummary,
  OfferDetail,
  Participant,
  Workflow,
  WorkspaceSummary,
} from "../../landconnex-api-client";
import { RootState } from "../../app/store";
import { completeTask, getWorkspace, newOffer, refreshTasks } from "./thunks";

export enum WorkspaceStatus {
  initial,
  loading,
  ready,
  workspaceDirty,
  failure,
}
export interface WorkspaceState {
  status: WorkspaceStatus;
  workspace: WorkspaceSummary;
  participants: Array<Participant>;
  offers: Array<OfferDetail>;
  newOfferId?: number;
  actionQuestion?: string;
  failureReason?: string;
  documents: Array<DocumentSummary>;
  workflow?: Workflow;
  activityLog: Array<ActivityLog>;
}

const initialState: WorkspaceState = {
  status: WorkspaceStatus.initial,
  workspace: {},
  participants: [],
  offers: [],
  documents: [],
  activityLog: [],
};

export const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    initialise: (state) => {
      state.status = WorkspaceStatus.initial;
    },
  },
  extraReducers: (builder) => {
    getWorkspaceReducer(builder);
    completeTaskReducer(builder);
    newOfferReducer(builder);
    refreshTasksReducer(builder);
  },
});

function newOfferReducer(builder: ActionReducerMapBuilder<WorkspaceState>) {
  builder.addCase(newOffer.fulfilled, (state, action) => {
    const o: OfferDetail = {
      offer: {
        id: action.payload.offerId,
        status: "not-started",
        hasEncumbrances: false,
        hasNeighbourhoodDisputes: false,
        hasPool: false,
        hasPoolCertificate: false,
        hasSafetySwitches: false,
        hasSellerSolicitor: false,
        hasSmokeAlarms: false,
        hasTenant: false,
      },
      participants: [],
    };
    state.offers.push(o);
  });
}
function refreshTasksReducer(builder: ActionReducerMapBuilder<WorkspaceState>) {
  builder.addCase(refreshTasks.fulfilled, (state, action) => {
    state.workspace = action.payload.workspace!;
    state.participants = removeRoles(action.payload.participants!, [
      "agentLicencee",
    ]);
    state.offers = action.payload.offers!;

    state.actionQuestion = "";
    state.status = WorkspaceStatus.ready;
    state.workflow = action.payload.workflow;
    state.documents = action.payload.documents!;
    state.activityLog = action.payload.activityLog!;
  });
}
function completeTaskReducer(builder: ActionReducerMapBuilder<WorkspaceState>) {
  builder.addCase(completeTask.fulfilled, (state) => {
    state.status = WorkspaceStatus.workspaceDirty;
  });
}
function getWorkspaceReducer(builder: ActionReducerMapBuilder<WorkspaceState>) {
  builder.addCase(getWorkspace.pending, (state) => {
    state.status = WorkspaceStatus.loading;
  });
  builder.addCase(getWorkspace.fulfilled, (state, action) => {
    state.workspace = action.payload.workspace!;
    state.participants = removeRoles(action.payload.participants!, [
      "agentLicencee",
    ]);
    state.offers = action.payload.offers!;

    state.actionQuestion = "";
    state.status = WorkspaceStatus.ready;
    state.workflow = action.payload.workflow;
    state.documents = action.payload.documents!;
    state.activityLog = action.payload.activityLog!;
  });
}

function removeRoles(
  participants: Array<Participant>,
  roles: Array<string>
): Array<Participant> {
  const filteredParticipants: Array<Participant> = [];
  participants.forEach((p) => {
    if (p.role && !roles.includes(p.role!)) {
      filteredParticipants.push(p);
    }
  });
  return filteredParticipants;
}

export const { initialise } = workspaceSlice.actions;
export default workspaceSlice.reducer;
export const workspaceState = (state: RootState) => state.workspace;
