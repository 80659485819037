import {
  Participant,
  Property as ApiProperty,
  AgentAppointment,
  Contract,
} from "../landconnex-api-client";

const validateParticipant = (participant: Participant, type: string) => {
  const errors = [];

  if (participant.participantType === "individual") {
    if (!participant.firstName && !participant.lastName) {
      errors.push(`${type} first name and last name are missing`);
    } else {
      if (!participant.firstName) errors.push(`${type} first name is missing`);
      if (!participant.lastName) errors.push(`${type} last name is missing`);
    }
  } else {
    if (!participant.organisationName) {
      errors.push(`${type} organisation name is missing`);
      if (!participant.abn) errors.push(`${type} ABN is missing`);
      if (!participant.acn) errors.push(`${type} ACN is missing`);
    }
  }

  if (!participant.phone) errors.push(`${type} phone number is missing`);
  if (!participant.email) errors.push(`${type} email is missing`);
  if (!participant.streetAddress1) errors.push(`${type} address is missing`);

  return errors;
};

export const validateClientDetails = (clients: Participant[]) =>
  clients.flatMap((client, index) => {
    const errors = validateParticipant(client, `Client ${index + 1}`);
    return errors.length > 0
      ? [`Client ${index + 1} Details, ${errors.join(", ")}`]
      : [];
  });

export const validateLicenceeDetails = (
  licencee: Participant,
  sellerAgent: Participant
) => {
  const licenceeErrors = validateParticipant(licencee, "Licencee");
  const agentErrors = validateParticipant(sellerAgent, "Agent");

  const errors = [...licenceeErrors, ...agentErrors];
  return errors.length > 0 ? [`Licencee Details, ${errors.join(", ")}`] : [];
};

export const validateProperty = (property: ApiProperty) => {
  const errors = [];
  if (!property.streetAddress1) errors.push(`Property address is missing`);
  return errors.length > 0 ? [`Property Details, ${errors.join(", ")}`] : [];
};

export const validateAppointment = (appointment: AgentAppointment) => {
  const errors = [];
  if (!appointment.appointmentTermStart)
    errors.push("Term of Appointment is missing");
  if (!appointment.priceList) errors.push("Price is missing");
  if (!appointment.priceMarketingRange)
    errors.push("Marketing Range is missing");
  return errors.length > 0 ? [`Appointment Details, ${errors.join(", ")}`] : [];
};

export const validatePropertySale = (appointment: AgentAppointment) => {
  const errors = [];
  if (!appointment.appointmentType) errors.push(`Appointment Type is missing`);
  if (!appointment.isSoleOrExclusiveContinuation)
    errors.push("Sole or Exclusive Field is missing");
  return errors.length > 0
    ? [`Property Sale Details, ${errors.join(", ")}`]
    : [];
};

export const validateAuthorisation = (authorisation: AgentAppointment) => {
  const errors = [];
  if (!authorisation.marketingInstructions)
    errors.push(`Description is missing`);
  if (!authorisation.marketingAuthorisedAmount)
    errors.push(`Authorised Amount is missing`);
  if (!authorisation.marketingCostsDueDate)
    errors.push(`When Payable is missing`);
  return errors.length > 0
    ? [`Authorisation Details, ${errors.join(", ")}`]
    : [];
};

export const validateBuyersDetails = (buyers: Participant[]) =>
  buyers.flatMap((buyer, index) => {
    const errors = validateParticipant(buyer, `Buyer ${index + 1}`);
    return errors.length > 0
      ? [`Buyer's ${index + 1} Details, ${errors.join(", ")}`]
      : [];
  });

export const validateBuyersSolicitor = (buyerSolicitor: Participant) => {
  const errors = validateParticipant(buyerSolicitor, "Buyer's Solicitor");
  return errors.length > 0
    ? [`Buyer's Solicitor Details, ${errors.join(", ")}`]
    : [];
};

export const validateOffer = (offer: Contract) => {
  const errors = [];
  if (!offer.purchasePrice) errors.push(`Purchase Price is missing`);
  return errors.length > 0 ? [`Offer Details, ${errors.join(", ")}`] : [];
};

export const validateSettlement = (settlement: Contract) => {
  const errors = [];
  if (!settlement.settlementDate) errors.push(`Date is missing`);
  return errors.length > 0 ? [`Settlement Details, ${errors.join(", ")}`] : [];
};

export const validateSellerDetails = (sellers: Participant[]) =>
  sellers.flatMap((seller) => {
    const errors = validateParticipant(seller, "Seller");
    return errors.length > 0 ? [`Seller's Details, ${errors.join(", ")}`] : [];
  });

export const validateSellerSolicitor = (sellerSolicitor: Participant) => {
  const errors = validateParticipant(sellerSolicitor, "Seller's Solicitor");
  return errors.length > 0 ? [`Seller's Solicitor, ${errors.join(", ")}`] : [];
};

export const validateContractProperty = (property: ApiProperty) => {
  const errors = [];
  if (!property.lga) errors.push(`Local Government is missing`);
  return errors.length > 0 ? [`Property, ${errors.join(", ")}`] : [];
};

export const validateEncumbrances = (contract: Contract) => {
  const errors = [];
  if (contract.hasEncumbrances && !contract.encumbrances)
    errors.push(`Encumbrances are missing`);
  if (contract.hasTenant) {
    if (!contract.tenancyTenantName) errors.push(`Tenant Name is missing`);
    if (!contract.tenancyTermAndOptions)
      errors.push(`Terms & Options are missing`);
    if (!contract.tenancyTermStart) errors.push(`Term Start is missing`);
    if (!contract.tenancyTermEnd) errors.push(`Term End is missing`);
    if (!contract.tenancyRent) errors.push(`Rent is missing`);
    if (!contract.tenancyBond) errors.push(`Bond is missing`);
  }
  return errors.length > 0 ? [`Property, ${errors.join(", ")}`] : [];
};
