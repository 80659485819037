import {
  Box,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  debounce,
  useTheme,
} from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  Participant,
  ParticipantParticipantTypeEnum,
} from "../../../landconnex-api-client";
import { OnParticipantChanged } from "../../../components/events";
import AddressForm from "../../../components/address-form";
import ConfirmationDialog from "../../../components/confirmation-dialog";
import ContactDetails from "../../../components/contact-details";
import ParticipantNameForm from "../../../components/participant-name-form";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

export interface BuyerDetailProps {
  buyer: Participant;
  index: number;
  onChanged: OnParticipantChanged;
  onDelete: OnParticipantChanged;
}

const BuyerDetail: FC<BuyerDetailProps> = ({
  buyer,
  onChanged,
  onDelete,
  index,
}) => {
  const [confirmationDialogState, setConfirmationDialogState] = useState(
    Array<number>()
  );
  const theme = useTheme();

  const { handleSubmit, control, formState, setValue, reset, trigger } =
    useForm<Participant>({
      defaultValues: buyer,
     // resolver: yupResolver(schema),
    });

  useEffect(() => {
    reset(buyer);
    trigger();
  }, [buyer]);

  const onSubmit: SubmitHandler<Participant> = (data) => {
    onChanged(data);
  };

  const watchedData = useWatch({
    control: control,
    defaultValue: buyer,
  });
  const debouncedSave = useCallback(
    debounce(() => {
      console.log("Saving");
      handleSubmit(onSubmit)();
    }, 1000),
    []
  );

  function handleDeleteBuyer(participant: Participant): void {
    setConfirmationDialogState([participant.id!]);
  }

  useDeepCompareEffect(() => {
    console.log("Triggered");
    if (formState.isDirty) {
      debouncedSave();
    }
  }, [watchedData]);
  return (
    <Box
      key={buyer.id}
      sx={{
        maxWidth: "1024px",
        margin: "15px auto",
        padding: "30px",
        background: "#faf7f7",
        borderRadius: "30px",
        boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
      }}
    >
      <Grid item xs={12} sm={12}>
        <ConfirmationDialog
          open={confirmationDialogState.includes(buyer.id!)}
          message={`Remove ${
            watchedData.organisationName ||
            (watchedData.firstName && watchedData.lastName
              ? `${watchedData.firstName} ${watchedData.lastName}`
              : `Buyer ${index + 1}`)
          } from the contract?`}
          title="Remove buyer?"
          onClose={(result) => {
            if (result) {
              onDelete(buyer);
            }
            setConfirmationDialogState([]);
          }}
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">
            {`${
              watchedData.organisationName ||
              (watchedData.firstName && watchedData.lastName
                ? `${watchedData.firstName} ${watchedData.lastName}`
                : `Buyer ${index + 1}`)
            }`}
          </Typography>
          <IconButton
            color="primary"
            onClick={() => handleDeleteBuyer(buyer)}
            sx={{
              "&:hover": {
                backgroundColor: "rgba(0,0,0,0)",
                "& .MuiTypography-root": {
                  color: "#555",
                },
                "& .MuiSvgIcon-root": {
                  color: "#555",
                },
              },
            }}
          >
            <DeleteForeverOutlinedIcon
              fontSize="large"
              sx={{ color: "#8F1219" }}
            />
          </IconButton>
        </Box>
        <Divider />
      </Grid>

      {/* Select buyer type */}
      <Grid item xs={8} sm={5} md={4} marginTop={2}>
        <FormControl fullWidth variant="filled">
          <InputLabel size="small" id="buyerTypeLabel">
            Buyer Type
          </InputLabel>
          <Select
            size="small"
            labelId="buyerTypeLabel"
            id="buyerType"
            label="Buyer Type"
            value={
              buyer.participantType ?? ParticipantParticipantTypeEnum.Individual
            }
            onChange={(e) => {
              const updated = { ...buyer };
              const p = Object.values(ParticipantParticipantTypeEnum).find(
                (value) => value === e.target.value
              ) as ParticipantParticipantTypeEnum;
              updated.participantType = p;
              onChanged(updated);
            }}
          >
            {Object.values(ParticipantParticipantTypeEnum).map((p) => (
              <MenuItem key={p} value={p}>
                {p}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/* buyer name form */}
      <Grid item xs={12} sm={12} marginTop={2}>
        <Typography
          variant="h6"
          color={theme.palette.text.secondary}
          marginBottom={1}
        >
          Name
        </Typography>
      </Grid>
      <ParticipantNameForm
        control={control}
        captureGst={true}
        participantType={buyer.participantType ?? "individual"}
      />
      <Grid container spacing={2}>
        {/* Contact details */}
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" color={theme.palette.text.secondary}>
            Contact Details
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <ContactDetails control={control} />
        </Grid>

        {/* Address form */}
        <Grid item xs={12} sm={12}>
          <Typography variant="h6" color={theme.palette.text.secondary}>
            Address
          </Typography>
        </Grid>
        <AddressForm control={control} address={buyer} setValue={setValue} />
      </Grid>
    </Box>
  );
};

export default BuyerDetail;
