import { FC } from "react";
import { WorkspaceState } from "../workspace-slice";
import { OnWorkspaceAction } from "../events";
import WorkspaceView from "./workspace-view";

import { useNavigate } from "react-router-dom";
import AgentHeader from "./agent-header";
import { Activity } from "../../../landconnex-api-client";

export interface SolicitorViewProps {
  state: WorkspaceState;
  onEditContract: OnWorkspaceAction;
}
const SolicitorView: FC<SolicitorViewProps> = ({ state }) => {
  const navigate = useNavigate();
  const handleTaskOpened = (task: Activity) => {
    switch (task.name) {
      case "contract-preparation-disclosures":
        navigate("contract");
        break;

      default:
        break;
    }
  };

  return (
    <WorkspaceView
      header={
        <AgentHeader
          workspaceSummary={state.workspace}
          participants={state.participants}
          workflow={state.workflow!}
        />
      }
      state={state}
      offers={state.offers}
      workflow={state.workflow!}
      participants={state.participants}
      documents={state.documents}
      workspaceSummary={state.workspace}
      onTaskOpened={handleTaskOpened}
      onOfferClicked={function (_: number): void {
        throw new Error("Function not implemented.");
      }}
      onDocumentClicked={function (document: string): void {
        navigate(`document/${document}`);
      }}
      showOffers={false}
      onInviteSellerSolicitor={() => null}
      onInviteBuyerSolicitor={() => null}
      onSendToSeller={() => null}
      onSendToBuyer={() => null}
      onShareWithSeller={() => null}
    />
  );
};

export default SolicitorView;
