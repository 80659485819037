import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import { OfferDetail, Participant, Workflow, Workspace } from "../../landconnex-api-client";
import { OnOfferClicked } from "../events";
import OfferCard from "./offer-card";
import { useAppDispatch } from "../../app/hooks";
import { createOffer } from "../../features/offers/thunks";
export interface OfferTableProps {
  offers: OfferDetail[];
  workspace: Workspace;
  workflow: Workflow;
  participants?: Participant[];
  onOfferClicked: OnOfferClicked;
  onSendToSeller: OnOfferClicked;
  onSendToBuyer: OnOfferClicked;
}

const OfferTable: FC<OfferTableProps> = ({ offers, workspace, workflow }) => {
  const dispatch = useAppDispatch();
  return (
    <Grid container spacing={2} maxWidth={1500} justifyContent={`center`}>
      {offers.length > 0 ? (
        offers.map((offer, index) => (
          <Grid
            key={index}
            item
            xl={6}
            xs={12}
            sx={{
              maxWidth: { md: "100%", lg: "550px" },
            }}
          >
            <OfferCard
              offer={offer}
              // offerState={workflow.offers.find((o) => o.offerId === offer.offer.id)}
              workspace={workspace}
              workflow={workflow}
            />
          </Grid>
        ))
      ) : (
        <Grid
          item
          xs={12}
          sx={{ padding: 1, marginBottom: 5, textAlign: "center" }}
        >
          <Typography variant="h6">No offers available</Typography>
          <Typography variant="body1">
            {workspace.role !== "sellerAgent" ? (
              "Please contact agent to start"
            ) : (
              <>
                Please{" "}
                <Typography
                  component="span"
                  onClick={() => dispatch(createOffer(workspace.id))}
                  sx={{
                    color: "green",
                    cursor: "pointer",
                  }}
                >
                  create new
                </Typography>{" "}
                offer to start
              </>
            )}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
export default OfferTable;
