import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { FC, ReactElement } from "react"
import DocumentsTable, {
  OnDocumentClicked,
} from "../../../components/documents-table"
import {
  DocumentSummary,
  OfferDetail,
  Participant,
  Workflow,
  WorkspaceRoleEnum,
  WorkspaceSummary,
} from "../../../landconnex-api-client"
import {
  OnInviteBuyerSolicitor,
  OnInviteSolicitor,
  OnTaskOpened,
} from "../events"
import { OnOfferClicked, OnWorkspaceEvent } from "../../../components/events"
import LCCard from "../../../components/cards/lc-card"
import { WorkspaceState } from "../workspace-slice"
import ActivityLog from "./activity-log"
import ParticipantList from "./participants-list"
import BottomNavToggle from "./bottom-nav"
import { Outlet, Link } from "react-router-dom"
import SelectSolicitor, { ActingForEnum } from "./actions/select-solicitor"
import { useAppSelector } from "../../../app/hooks"
import { sessionState } from "../../session/session-slice"
import Timeline from "../../timeline/timeline"
import { singleLineFullAddress } from "../../../helpers/helpers"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined"
import PollOutlinedIcon from "@mui/icons-material/PollOutlined"

export interface WorkspaceViewProps {
  state: WorkspaceState
  header: ReactElement
  participants: Participant[]
  documents: DocumentSummary[]
  workspaceSummary: WorkspaceSummary
  onTaskOpened: OnTaskOpened
  onOfferClicked: OnOfferClicked
  onOfferCreated?: OnWorkspaceEvent
  onDocumentClicked: OnDocumentClicked
  onInviteSellerSolicitor: OnInviteSolicitor
  onInviteBuyerSolicitor: OnInviteBuyerSolicitor
  onSendToSeller: OnOfferClicked
  onSendToBuyer: OnOfferClicked
  onShareWithSeller: OnOfferClicked
  offers?: OfferDetail[]
  showOffers: boolean
  workflow: Workflow
}

const WorkspaceView: FC<WorkspaceViewProps> = ({
  state,
  participants,
  workspaceSummary,
  onDocumentClicked,
  documents = [],
  workflow,
  onInviteSellerSolicitor,
  onInviteBuyerSolicitor,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const sessState = useAppSelector(sessionState)
  const filterParticipantsByRole = (participant: Participant) => {
    return (
      (participant.role === "seller" ||
        participant.role === "sellerSolicitor" ||
        participant.role === "buyer" ||
        participant.role === "buyerSolicitor") &&
      participant.phone
    )
  }

  const timelineIsVisible =
    state.workspace.workspace?.role == WorkspaceRoleEnum.SellerAgent ||
    state.workspace.workspace?.role == WorkspaceRoleEnum.SellerSolicitor

  // const agent = state.participants.find(
  //   (participant) => participant.role! === "sellerAgent"
  // );

  return (
    <>
      <Grid container>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <LCCard
              title={`${workspaceSummary.workspace?.id} - ${singleLineFullAddress(
                workspaceSummary.property!,
              )}`}
              titleColor="#000"
              txtColor="#000"
              bgColor="#fff"
              sxCardHeader={{ paddingBottom: 0 }}
              sxCardContent={{ paddingBottom: 0 }}
              titleExtra={
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 3,
                    color: "#3e3e3e",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Typography
                      variant="body2"
                      component="span" // Use 'span' to prevent rendering as <p>
                      sx={{
                        fontWeight: 500,
                        marginRight: 1,
                        marginLeft: 0.5,
                      }}
                    >
                      <Link to={`/open-house/${workspaceSummary.workspace?.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        Open House
                      </Link>
                    </Typography>
                  </Box>
                  {[
                    // {
                    //   label: "Property",
                    //   value: singleLineFullAddress(workspaceSummary.property!),
                    //   icon: <WhereToVoteOutlinedIcon sx={{ fontSize: 20 }} />,
                    // },
                    {
                      label: "Lot",
                      value: workspaceSummary.property?.lot || "N/A",
                      icon: <HomeOutlinedIcon />,
                    },
                    {
                      label: "Title Reference",
                      value: workspaceSummary.property?.titleReference || "N/A",
                      icon: <SubtitlesOutlinedIcon />,
                    },
                    {
                      label: "Plan",
                      value: workspaceSummary.property?.plan || "N/A",
                      icon: <PollOutlinedIcon />,
                    },
                  ].map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item.icon}
                      <Typography
                        variant="body2"
                        component="span"
                        sx={{
                          fontWeight: 500,
                          marginRight: 1,
                          marginLeft: 0.5,
                        }}
                      >
                        {item.label}:
                      </Typography>
                      <Typography variant="body2" component="span">
                        {item.value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              }
            >
              {timelineIsVisible && <Timeline workflow={workflow} />}
            </LCCard>
          </Grid>

          <Grid item lg={8} md={7} sm={12}>
            <Box
              sx={{
                borderRadius: "12px",
              }}
            >
              {state.workflow?.subState == "agentAppointmentAwaitingSigning" ? (
                <LCCard
                  title={""}
                  titleColor="#000"
                  txtColor="#000"
                  bgColor="#fff"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    minHeight="200px"
                  >
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginTop: "16px" }}>
                      The agent appointment has been sent for signing. Please
                      check your email.
                    </Typography>
                  </Box>
                </LCCard>
              ) : state.workflow?.subState ==
                "agentAppointmentAwaitingSellerAdmission" ? (
                <LCCard
                  title={""}
                  titleColor="#000"
                  txtColor="#000"
                  bgColor="#fff"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    minHeight="200px"
                  >
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginTop: "16px" }}>
                      Waiting for Seller to Accept the Invitation
                    </Typography>
                  </Box>
                </LCCard>
              ) : (
                <Outlet></Outlet>
              )}
              {state.workflow?.subState ==
                "contractPreparationAwaitingSellerSolicitorSelection" &&
                (state.workspace.workspace?.role == "seller" ||
                  state.workspace.workspace?.role == "buyer") && (
                  <SelectSolicitor
                    actingFor={
                      state.workspace.workspace?.role == "seller"
                        ? ActingForEnum.Seller
                        : ActingForEnum.Buyer
                    }
                    onInviteBuyerSolicitor={onInviteBuyerSolicitor}
                    onInviteSellerSolicitor={onInviteSellerSolicitor}
                  />
                )}
              {state.workflow?.subState ==
                "contractPreparationAwaitingSellerSolicitorAdmission" &&
                (state.workspace.workspace?.role == "seller" ||
                  state.workspace.workspace?.role == "buyer") && (
                  <LCCard
                    title={""}
                    titleColor="#000"
                    txtColor="#000"
                    bgColor="#fff"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      minHeight="200px"
                    >
                      <CircularProgress />
                      <Typography variant="h6" style={{ marginTop: "16px" }}>
                        Waiting for Solicitor
                      </Typography>
                    </Box>
                  </LCCard>
                )}
            </Box>
            {/* <LCCard
              title={`Participants`}
              titleColor="#000"
              bgColor="#fff"
              txtColor="#000"
              sxCard={{ marginTop: 2 }}
            >
              <ParticipantList
                participants={participants}
                currentUserEmail={sessState.details?.email!}
                roles={[
                  "sellerAgent",
                  "seller",
                  "sellerSolicitor",
                  "buyer",
                  "buyerSolicitor",
                ]}
              />
            </LCCard> */}
          </Grid>
          <Grid item lg={4} md={5} xs={12}>
            <Box
              sx={{
                margin: "0 auto",
                position: { lg: "sticky", xs: "relative" },
                top: { lg: 89, xs: 0 },
                flex: "3",
                width: { lg: "auto", sm: "100%" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <LCCard
                  title={`Participants`}
                  titleColor="#000"
                  bgColor="#fff"
                  txtColor="#000"
                >
                  <ParticipantList
                    participants={participants}
                    currentUserEmail={sessState.details?.email!}
                    roles={[
                      "sellerAgent",
                      "seller",
                      "sellerSolicitor",
                      "buyer",
                      "buyerSolicitor",
                    ]}
                  />
                </LCCard>
                <LCCard
                  title={"Documents"}
                  txtColor="#273E47"
                  bgColor="#014597"
                  circleElementBefore="#4a84ad"
                  circleElementAfter="#023676"
                >
                  <DocumentsTable
                    onDocumentViewClicked={onDocumentClicked}
                    documents={documents}
                  />
                </LCCard>

                <LCCard title={"Activity Log"} bgColor="#fff" titleColor="#000">
                  <ActivityLog activityLog={state.activityLog} />
                </LCCard>
              </Box>
            </Box>
            {/* </Box> */}
          </Grid>
        </Grid>
      </Grid>
      {isMobile && (
        <Box sx={{ position: "relative" }}>
          <BottomNavToggle
            participants={state.participants}
            filterFunction={filterParticipantsByRole}
          />
        </Box>
      )}
    </>
  )
}

export default WorkspaceView
