import {
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import { getDraft, getPDF } from "./thunks";
import { Contract, Participant, Property } from "../../landconnex-api-client";
import { createAppSlice } from "../../app/createAppSlice";

export enum OfferDraftStatus {
  initial,
  loadingPending,
  loadingFulfilled,
  pdfReadyToDownload,
  ready,
  dirty,
}

export interface OfferDraftState {
  status: OfferDraftStatus;
  draft: string;
  pdfUrl: string;
  contract: Contract;
  property: Property;
  participants: Array<Participant>;
  offer: Contract;
  filename: string;
}
const initialState: OfferDraftState = {
  status: OfferDraftStatus.initial,
  draft: "",
  pdfUrl: "",
  filename: "",
  contract: {
    id: 0,
    status: "",
    hasEncumbrances: false,
    hasNeighbourhoodDisputes: false,
    hasPool: false,
    hasPoolCertificate: false,
    hasSafetySwitches: false,
    hasSellerSolicitor: false,
    hasSmokeAlarms: false,
    hasTenant: false,
  },

  property: {},
  participants: [],
  offer: { 
    id: 0,
    status: "",
    hasEncumbrances: false,
    hasNeighbourhoodDisputes: false,
    hasPool: false,
    hasPoolCertificate: false,
    hasSafetySwitches: false,
    hasSellerSolicitor: false,
    hasSmokeAlarms: false,
    hasTenant: false,
   },
};

export const offerDraftSlice = createAppSlice({
  name: "offerDraft",
  initialState,
  reducers: {
    nameUpdated: (state) => {
     // state.offer.id = action.payload;
      state.status = OfferDraftStatus.dirty;
    },
    pdfDownloadCompleted: () => {
      // state.status = OfferDraftStatus.ready;
    },
  },
  extraReducers: (builder) => {
    getDraftReducer(builder);
    getPDFReducer(builder);
  },
  selectors: {
    offerDraftState: offerDraft => offerDraft,
  }
});

function getDraftReducer(builder: ActionReducerMapBuilder<OfferDraftState>) {
  builder.addCase(getDraft.pending, (state) => {
    state.status = OfferDraftStatus.loadingPending;
  });
  builder.addCase(getDraft.fulfilled, (state, action) => {
    state.draft = action.payload;

    state.status = OfferDraftStatus.loadingFulfilled;
  });
}

function getPDFReducer(builder: ActionReducerMapBuilder<OfferDraftState>) {
  builder.addCase(getPDF.pending, (state) => {
    //state.pdf = action.payload;

    state.status = OfferDraftStatus.loadingPending;
  });
  builder.addCase(getPDF.fulfilled, (state, action) => {
    //state.pdf = action.payload;

    state.contract = action.payload.contract;
    state.pdfUrl = action.payload.url;
    state.status = OfferDraftStatus.pdfReadyToDownload;
    state.property = action.payload.property;
    state.participants = action.payload.participants;
    state.offer = action.payload.offer;
    // if (!state.offer.name || state.offer.name == "") {
    //   state.offer.name = generateFilename(state.participants);
    // }
    //state.filename = generateFilename(state.property);
  });
}
// function generateFilename(participants: Array<Participant>): string {
//   let name = "";
//   let index = 0;
//   participants.forEach((p) => {
//     name = name + `${index > 0 ? ", " : ""} ${p.firstName} ${p.lastName}`;
//     index++;
//   });

//   return name;
// }

export const { pdfDownloadCompleted, nameUpdated } = offerDraftSlice.actions;
export const { offerDraftState } = offerDraftSlice.selectors;

