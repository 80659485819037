import { Box, Typography } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import HtmlTooltip from "../tool-tip";

interface OfferDataProps {
  icon?: JSX.Element;
  label?: string;
  value?: any;
  isCheck?: boolean;
  hideValue?: boolean;
}

const OfferData = ({
  icon,
  label,
  value,
  isCheck = false,
  hideValue = false,
}: OfferDataProps) => (
  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
    {isCheck ? (
      value ? (
        <CheckOutlinedIcon sx={{ color: "#2e7d32" }} />
      ) : (
        <CloseOutlinedIcon sx={{ color: "#d32f2f" }} />
      )
    ) : (
      icon
    )}
    <HtmlTooltip
      title={
        value && (
          <>
            <Typography color="inherit">{label}</Typography>
            <em>{value}</em>
          </>
        )
      }
    >
      <Typography variant="subtitle1">
        {label} {!hideValue && value}
      </Typography>
    </HtmlTooltip>
  </Box>
);

export default OfferData;
