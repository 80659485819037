import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { accept } from "./thunks";
import { createAppSlice } from "../../app/createAppSlice";

export enum AcceptInvitationStatus {
  initial,
  acceptInvitationPending,
  acceptInvitationFulfilled,
  failure,
}

export interface AcceptInvitationState {
  status: AcceptInvitationStatus;
  invitation: string;
}

const initialState: AcceptInvitationState = {
  status: AcceptInvitationStatus.initial,
  invitation: "",
};

export const acceptInvitationSlice = createAppSlice({
  name: "acceptInvitation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    acceptReducers(builder);
  },
  selectors: {
    selectAcceptInvitationState: acceptInvitation => acceptInvitation,
  },
});

function acceptReducers(
  builder: ActionReducerMapBuilder<AcceptInvitationState>
) {
  builder.addCase(accept.pending, (state) => {
    state.status = AcceptInvitationStatus.acceptInvitationPending;
  });
  builder.addCase(accept.rejected, (state) => {
    state.status = AcceptInvitationStatus.failure;
  });
  builder.addCase(accept.fulfilled, (state, action) => {
    state.status = AcceptInvitationStatus.acceptInvitationFulfilled;
    state.invitation = action.payload;
  });
}

export const {} = acceptInvitationSlice.actions;
export const { selectAcceptInvitationState } = acceptInvitationSlice.selectors;

