import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import {
  StripePaymentElementOptions,
  StripeAddressElementOptions,
} from "@stripe/stripe-js"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { completeSubscription } from "./thunks"
import { selectCreateSubscriptionState } from "./create-subscription-slice"
import LCCard from "../../components/cards/lc-card"
import { Box } from "@mui/material"
import LCButton from "../../components/button"

// interface SubscriptionDetailsProps {
//     stripePromise: Promise<Stripe | null>
// }
const SubscriptionDetails = () => {
  const elements = useElements()
  const stripe = useStripe()
  const dispatch = useAppDispatch()
  const state = useAppSelector(selectCreateSubscriptionState)

  const paymentOptions: StripePaymentElementOptions = {}

  let addressOptions: StripeAddressElementOptions = {
    mode: "billing",

    display: { name: "full" },
    autocomplete: {
      mode: "automatic",
    },
    allowedCountries: ["AU"],
  }

  const handleCreate = async (event: any) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    let confirmSetupOptions = {
      elements: elements,
      confirmParams: {
        return_url: "http://localhost:5173/subscriptions/complete",
      },
    }

    elements?.submit()

    let result = await stripe?.confirmSetup(confirmSetupOptions)

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message)
    } else {
      dispatch(
        completeSubscription({
          setupIntentID: state.setupIntentID!,
        }),
      )
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (
    <LCCard
      title={"Billing Details"}
      titleColor="#000"
      txtColor="#000"
      bgColor="#fff"
    >
      <Box
        sx={{
          maxWidth: 1000,
          margin: "0 auto",
        }}
      >
        <LCCard
          sxCard={{
            width: "100%",
            minHeight: 300,
            outline: "2px solid rgba(208, 226, 255, .5)",
          }}
        >
          <form>
            <AddressElement options={addressOptions} />
            <PaymentElement options={paymentOptions} />
            <Box sx={{ width: 150, margin: "30px auto" }}>
              <LCButton label="Create" color="#8F1219" onClick={handleCreate} />
            </Box>
          </form>
        </LCCard>
      </Box>
    </LCCard>
  )
}

export default SubscriptionDetails
