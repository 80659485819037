import React, { ReactNode } from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

interface Note {
  title: string;
  items: string[];
}

interface FormWrapperProps {
  title: string;
  notes?: Note[];
  children: ReactNode;
}

const FormWrapper: React.FC<FormWrapperProps> = ({
  title,
  notes,
  children,
}) => (
  <Box sx={{ maxWidth: 1024, margin: "15px auto" }}>
    <Box
      sx={{
        backgroundColor: "#8F1219",
        color: "#fff",
        padding: "10px 15px",
        marginBottom: "30px",
      }}
    >
      <Typography variant="h5">{title}</Typography>
    </Box>

    {notes?.map(({ title, items }, index) => (
      <Box
        key={index}
        sx={{
          backgroundColor: "#f0eeeb",
          borderRadius: "15px",
          padding: "15px",
          textAlign: "justify",
          marginBottom: "15px",
        }}
      >
        <Typography variant="h6" sx={{ color: "#c20029" }}>
          {title}
        </Typography>
        <List
          sx={{
            padding: 0,
            paddingLeft: "30px",
            listStyleType: "disc",
            "& li::marker": {
              fontSize: "0.8em",
            },
          }}
        >
          {items.map((item, idx) => (
            <ListItem key={idx} sx={{ padding: 0, display: "list-item" }}>
              <ListItemText
                primary={item}
                primaryTypographyProps={{ sx: { fontSize: 14 } }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    ))}

    {children}
  </Box>
);

export default FormWrapper;
