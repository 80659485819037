import { FC } from "react";
import { Box, colors, Typography } from "@mui/material";

export enum TimelineStageStatus {
  NotStarted = "not-started",
  InProgress = "in-progress",
  Completed = "completed",
}

export interface TimelineStageProps {
  primaryColor: string;
  steps: number;
  currentStep: number;
  label: React.ReactNode; // Can be any valid JSX element, not a <p> tag
  alert?: boolean;
}

const TimelineStage: FC<TimelineStageProps> = ({
  steps,
  currentStep,
  label,
}) => {
  // Determine the status based on currentStep
  const status =
    currentStep === 0
      ? TimelineStageStatus.NotStarted
      : currentStep === steps
      ? TimelineStageStatus.Completed
      : TimelineStageStatus.InProgress;

  const progress = (currentStep / steps) * 100;

  // Define colors and styles for different states
  const statusColor =
    status === TimelineStageStatus.Completed
      ? colors.green[400]
      : status === TimelineStageStatus.InProgress
      ? colors.green[100]
      : colors.grey[100];

  const textColor =
    status === TimelineStageStatus.Completed ||
    status === TimelineStageStatus.InProgress
      ? colors.grey[900]
      : colors.grey[500];

  // Box style definitions for reuse
  const boxStyle = {
    position: "relative",
    width: "100%",
    height: "50px",
    backgroundColor: statusColor,
    borderRadius: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };

  const progressBarStyle = {
    position: "absolute",
    left: 0,
    top: 0,
    width: `${progress}%`,
    height: "100%",
    backgroundColor: colors.green[400],
    borderRadius: "0px",
    zIndex: 1,
  };

  const typographyStyle = {
    position: "relative",
    zIndex: 2,
    fontSize: "14px",
    color: textColor,
    textAlign: "center",
  };

  return (
    <Box sx={boxStyle}>
      <Box sx={progressBarStyle} />
      {/* Ensure no <p> tags are nested */}
      <Typography sx={typographyStyle}>{label}</Typography>
    </Box>
  );
};

export default TimelineStage;
