import { createAsyncThunk } from "@reduxjs/toolkit";
import { workspacesApi } from "../../api";
import { Invoice } from "../../landconnex-api-client";

export interface GetInvoiceArgs {
  workspaceId: number;
}
export const getInvoice = createAsyncThunk<Invoice, GetInvoiceArgs>(
  "workspace/getInvoice",
  async ({ workspaceId }) => {
   const response = await workspacesApi.getInvoice(workspaceId);
   return response.data;
  }
);
