import CircularProgress from "@mui/material/CircularProgress";
import { FC, useEffect, useRef, useCallback } from "react";
import PDFViewer from "../../../components/pdf-viewer";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import "./generate.css";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  agentAppointmentState,
  AgentAppointmentStatus,
  GeneratedPDFStatus,
} from "../agent-appointment-slice";
import {
  getAgentAppointmentPDF,
  sendAgentAppointmentForSigning,
} from "../thunks";
import StatusMessage from "./status-message";
import { Box } from "@mui/material";
import LCButton from "../../../components/button";

export interface GenerateProps {
  url: string;
  fileName: string;
  isLoading: boolean;
}

const Generate: FC = () => {
  const state = useAppSelector(agentAppointmentState);
  const params = useParams();
  const dispatch = useAppDispatch();
  const workspaceId = Number(params.workspaceId);
  const navigate = useNavigate();
  const pdfViewerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      state.pdfStatus === GeneratedPDFStatus.initial ||
      state.pdfStatus === GeneratedPDFStatus.stale
    ) {
      dispatch(getAgentAppointmentPDF(workspaceId));
    }
  }, [state.pdfStatus, dispatch, workspaceId]);

  const handleOnSendAgentAppointmentForSigning = useCallback(async () => {
    try {
      const success = await dispatch(
        sendAgentAppointmentForSigning(workspaceId)
      );
      if (success) {
        setTimeout(() => {
          navigate(`/workspaces/${workspaceId}`);
        }, 1500);
      } else {
        console.error("Failed to send agent appointment");
      }
    } catch (error) {
      console.error("Error occurred while sending agent appointment:", error);
    }
  }, [dispatch, workspaceId, navigate]);

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: pdfViewerRef.current?.offsetTop || 0,
      behavior: "smooth",
    });
  }, []);

  if (state.status === AgentAppointmentStatus.loading) {
    return (
      <Grid
        container
        spacing={2}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <CircularProgress size={100} />
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
    >
      <Grid item xs={12} sm={6}>
        <StatusMessage
          status={state.status}
          onSendAgentAppointmentForSigning={
            handleOnSendAgentAppointmentForSigning
          }
        />
      </Grid>
      <Grid
        container
        spacing={2}
        padding={2}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid id="pdf" item padding={2}>
          <Paper square>
            <Grid item xs={12} className="generate-container">
              <div ref={pdfViewerRef}>
                <PDFViewer url={state.pdf!} />
              </div>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ margin: "0 auto", width: "160px" }}>
        <LCButton label="Scroll to Top" onClick={scrollToTop} color="#014597" />
      </Box>
    </Grid>
  );
};

export default Generate;
