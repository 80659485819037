import {
  Box,
  CardActions,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import Page from "../../components/page";
import LCButton from "../../components/button";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { useEffect } from "react";
import SearchForProperty from "./search-for-property";
import SelectAgent from "./select-agent";
import { createWorkspace, getTenancyUsers } from "./thunks";
import { useNavigate } from "react-router-dom";
import { Property } from "../../landconnex-api-client";
import LCCard from "../../components/cards/lc-card";
import { createWorkspaceState, CreateWorkspaceStatus, initial } from "./create-workspace-slice";

const CreateWorkspacePage = () => {
  const dispatch = useAppDispatch();
  // const state = useAppSelector(createWorkspaceState);
  const state = useAppSelector(createWorkspaceState);
  

  const navigate = useNavigate();
  useEffect(() => {
    if (state.status === CreateWorkspaceStatus.initial) {
      dispatch(getTenancyUsers());
    }
    if (state.status === CreateWorkspaceStatus.worspaceCreated) {
      navigate(`/workspaces/${state.newWorkspaceId}`);
      dispatch(initial());
    }
  }, [state.status, dispatch]);

  const handleCreateWorkspace = () => {
    const st = state.addressDetails?.structured!;

    const [lot, plan] = state.selectedTitle?.lotOnPlan.split(" ")!;

    let streetAddress1 = "";
    if (state.addressDetails?.mla.length == 2) {
      streetAddress1 = state.addressDetails?.mla[0];
    } else {
      streetAddress1 = `${state.addressDetails?.mla[0]} ${state.addressDetails?.mla[1]}`;
    }
    const property: Property = {
      locality: st.locality?.name,
      postCode: st.postcode,
      stateOrTerritory: st.state?.abbreviation,
      streetAddress1: streetAddress1,
      titleReference: state.selectedTitle?.reference,
      lot: lot,
      plan: plan,
    };
    dispatch(
      createWorkspace({
        agentId: state.selectedAgent?.id!,
        property: property,
      })
    );
  };
  return (
    <Page>
      <Grid
        container
        sx={{
          maxWidth: 800,
          margin: "0 auto",
          padding: "50px 0",
        }}
      >
        <Grid item xs={12}>
          <LCCard
            title={"Create a new workspace"}
            titleColor="#000"
            txtColor="#000"
            bgColor="#fff"
            sxCard={{
              padding: { sm: "15px 30px", xs: "15px 0" },
              outline: "2px solid rgba(208, 226, 255, .5)",
            }}
          >
            <SearchForProperty />
            <SelectAgent />
            <CardActions sx={{ justifyContent: "center" }}>
              {state.status == CreateWorkspaceStatus.creatingWorkspace && (
                <Box sx={{ justifyItems: "center" }}>
                  <CircularProgress />
                  <Typography ml={2}>Creating workspace...</Typography>
                </Box>
              )}
              {state.status !== CreateWorkspaceStatus.creatingWorkspace && (
                <Box mt={2} maxWidth={200} width={"100%"}>
                  <LCButton
                    label="Create Workspace"
                    onClick={() => handleCreateWorkspace()}
                    color="#8F1219"
                    disabled={
                      state.addressDetails &&
                      state.selectedTitle &&
                      state.selectedAgent
                        ? false
                        : true
                    }
                  />
                </Box>
              )}
            </CardActions>
          </LCCard>
        </Grid>
      </Grid>
    </Page>
  );
};

export default CreateWorkspacePage;
