import { createAsyncThunk } from "@reduxjs/toolkit";
import { User } from "oidc-client-ts";
import { teamsApi } from "../../api";
import { jwtDecode } from "jwt-decode";

import { Participant } from "../../landconnex-api-client";
import { appConfig } from "../../constants";

interface SetSessionResponse {
  participant: Participant;
  roles: Array<string>;
}
export const setSession = createAsyncThunk<SetSessionResponse, void>(
  "session/setSession",
  async () => {
    const req = await teamsApi.getMyDetails();

    const roles = getRoles();
    const resp: SetSessionResponse = {
      roles: Array<string>(...roles),
      participant: req.data.user,
    };
    return resp;
  }
);

// export const getUser = createAsyncThunk<User | undefined, void>(
//   "session/getUser",
//   async () => {
//     const user = getUserFromStorage();

//     return user;
//   }
// );

function getRoles(): string[] {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${appConfig.authority}:${appConfig.clientId}`
  );

  if (oidcStorage) {
    const user = User.fromStorageString(oidcStorage);
    const token = jwtDecode(user.access_token, {}) as string;

    const roles = JSON.parse(JSON.stringify(token))["roles"];
    return roles;
  }
  return [];
}

// const getUserFromStorage = (): User | undefined => {
//   const oidcStorage = sessionStorage.getItem(
//     `oidc.user:${appConfig.authority}:${appConfig.clientId}`
//   );

//   if (!oidcStorage) {
//     return undefined;
//   }

//   return User.fromStorageString(oidcStorage);
// };
