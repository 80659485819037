import { useSearchParams } from "react-router-dom";
import Page from "../../components/page";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  CreateSubscriptionStatus,
  selectCreateSubscriptionState,
} from "./create-subscription-slice";
import { useEffect } from "react";
import { completeSubscription } from "./thunks";
import { Box, CircularProgress, Typography } from "@mui/material";
import RedirectToLogin from "../../components/redirect-to-login";
import { withAuthenticationRequired } from "react-oidc-context";

const CreateSubscriptionSuccess = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectCreateSubscriptionState);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (state.status == CreateSubscriptionStatus.initial) {
      const setupIntentID = searchParams.get("setup_intent");

      if (!setupIntentID) {
        console.log("missing setupIntentID");
      } else {
        dispatch(
          completeSubscription({
            setupIntentID: setupIntentID,
          })
        );
      }
    }
  }, []);

  if (state.status == CreateSubscriptionStatus.initial) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <CircularProgress />
        <Typography variant="h6" style={{ marginTop: "16px" }}>
          Working on it :-) Please wait...
        </Typography>
      </Box>
    );
  }
  if (state.status == CreateSubscriptionStatus.complete) {
    <Page title="Subscription Complete">
      <h1>Complete!</h1>
    </Page>;
  }
  return (
    <Page title="Subscription Complete">
      <h1>{searchParams.get("setup_intent")}</h1>
    </Page>
  );
};
export default withAuthenticationRequired(CreateSubscriptionSuccess, {
  OnRedirecting: () => <RedirectToLogin />,
});
