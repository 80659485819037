import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FC, Fragment, useEffect } from "react";
import FeesDataGrid from "./fees-data-grid";
import BenefitToAgentDataGrid from "./benefit-to-agent-data-grid";

import { Box } from "@mui/material";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import {
  AgentAppointment,
  BenefitToAgent,
  Fee,
} from "../../../landconnex-api-client";

import FormInputDatePicker from "../../../components/form-input-date-picker";
import FormWrapper from "./form-wrapper";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { agentAppointmentState } from "../agent-appointment-slice";
import {
  addBenefitToAgent,
  addFee,
  deleteBenefitToAgent,
  deleteFee,
  updateAgentAppointment,
  updateBenefitToAgent,
  updateFee,
} from "../thunks";
import FormInputText from "../../../components/form-input-text";
import FormInputNumeric from "../../../components/form-input-numeric";
import AutoSave from "../../../components/auto-save";


const Authorisation: FC = () => {
  const state = useAppSelector(agentAppointmentState);
  const params = useParams();
  const dispatch = useAppDispatch();
  const workspaceId = Number(params.workspaceId);


  
  // const schema = yup.object().shape<AgentAppointment>({
  //   commissionPaymentTermInstructions: yup
  //     .string()
  //     .when(["commissionPaymentTerm"], {
  //       is: (val: string) =>
  //         val == AgentAppointmentCommissionPaymentTermEnum.Other,
  //       then: () => yup.string().required(),
  //       otherwise: () => yup.string().notRequired(),
  //     }),
  //   marketingCostsDueDate: yup.date().required(),
  //   marketingAuthorisedAmount: yup.number().required(),
  //   repairsAndMaintenanceAuthorisedAmount: yup.number(),
  //   marketingInstructions: yup.string(),
  // });

  const agentAppointmentFormMethods = useForm<AgentAppointment>({
    defaultValues: state.agentAppointment,
 //  resolver: yupResolver(schema),
  });
  const { handleSubmit, control, reset } = agentAppointmentFormMethods;

  useEffect(() => {
    reset(state.agentAppointment);
  }, [state.agentAppointment]);

  const onSubmit: SubmitHandler<AgentAppointment> = (data) => {
    dispatch(
      updateAgentAppointment({
        workspaceId: workspaceId,
        agentAppointment: data,
      })
    );
  };

  function onFeeAdded(): void {
    dispatch(addFee(workspaceId));
  }

  function onFeeDeleted(fee: Fee): void {
    dispatch(deleteFee({ fee: fee, workspaceId: workspaceId }));
  }

  function onFeeChanged(fee: Fee): void {
    dispatch(updateFee({ fee: fee, workspaceId: workspaceId }));
  }

  function onBenefitToAgentDeleted(benefitToAgent: BenefitToAgent): void {
    dispatch(
      deleteBenefitToAgent({
        workspaceId: workspaceId,
        benefitToAgent: benefitToAgent,
      })
    );
  }

  function onBenefitToAgentChanged(benefitToAgent: BenefitToAgent): void {
    dispatch(
      updateBenefitToAgent({
        workspaceId: workspaceId,
        benefitToAgent: benefitToAgent,
      })
    );
  }

  function onBenefitToAgentAdded(): void {
    dispatch(addBenefitToAgent(workspaceId));
  }

  return (
    <FormProvider {...agentAppointmentFormMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormWrapper title="Authorisation to incurr fees, charges and expenses">
          <Box
            sx={{
              maxWidth: "1024px",
              margin: "15px auto",
              padding: "30px",
              background: "#faf7f7",
              borderRadius: "30px",
              boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              className="section-1"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                marginBottom: 4,
                borderBottom: "1px solid silver",
                paddingBottom: 5,
              }}
            >
              <Typography variant="h6" component="span" className="roboto-bold">
                Section 1 - Adveritising / Marketing
              </Typography>
              <Box>
                <Typography variant="body1">
                  <strong>To the client</strong>
                </Typography>
                <Typography variant="body2">
                  Your agent may either complete this section or attach
                  annexures of marketing/ advertising activities. In either
                  case, the authorised amount must be written here.
                </Typography>
              </Box>
              <Grid>
                <Fragment>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormInputText
                        control={control}
                        name="marketingInstructions"
                        label="Description"
                        multiline
                        minRows={5}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormInputNumeric
                        control={control}
                        name="marketingAuthorisedAmount"
                        label="Amount"
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <FormInputDatePicker
                        control={control}
                        label="When Payable"
                        name="marketingCostsDueDate"
                        required
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              </Grid>
            </Box>

            <Box
              className="section-2"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                marginBottom: 4,
                borderBottom: "1px solid silver",
                paddingBottom: 5,
              }}
            >
              <Typography variant="h6" component="span" className="roboto-bold">
                Section 2 - Repairs and maintenance
              </Typography>
              <Box>
                <Typography variant="body2">
                  <i>(if applicable)</i>
                </Typography>
                <Typography variant="h6">Residential Sales</Typography>
                <Typography variant="body2">
                  The maximum value of repairs and maintenance to be paid by the
                  agent without prior approval by the client is
                </Typography>
              </Box>
              <Grid item xs={12} lg={6}>
                {/* <FormInputNumeric
              control={control}
              label="Amount"
              name="repairsAndMaintenanceAuthorisedAmount"
            /> */}
                <FormInputNumeric
                  name="repairsAndMaintenanceAuthorisedAmount"
                  label="Amount"
                  control={control}
                />
              </Grid>
              <Typography variant="h6">Property management</Typography>
              <Typography variant="body2">Routine repairs</Typography>
              <Typography variant="body2">
                The maximum value of repairs and maintenance to be paid by the
                agent without prior approval by the client is
              </Typography>
              <Grid item xs={12} lg={6}>
                <FormInputNumeric
                  name="repairsAndMaintenancePropertyAuthorisedAmount"
                  label="Amount"
                  control={control}
                />
              </Grid>
            </Box>
            <Box></Box>

            <Box
              className="section-3"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                marginBottom: 4,
                borderBottom: "1px solid silver",
                paddingBottom: 5,
              }}
            >
              <Typography variant="h6" component="span" className="roboto-bold">
                Section 3 - Fees
              </Typography>
              <Box>
                <Typography variant="body2">
                  <i>Description of fees and charges.</i> <br />
                  <i>
                    The agent may either complete this section or attach
                    annexures.
                  </i>
                </Typography>
              </Box>
              <Grid>
                <FeesDataGrid
                  fees={state.fees}
                  onFeeChanged={onFeeChanged}
                  onFeeDeleted={onFeeDeleted}
                  onFeeAdded={onFeeAdded}
                />
              </Grid>
            </Box>

            <Box
              className="section-4"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography variant="h6" component="span" className="roboto-bold">
                Section 4 - Agent Benefits
              </Typography>
              <Box>
                <Typography variant="body1">
                  Agent’s rebate, discount, commission or benefit incurred in
                  the provision of or performance of the service
                </Typography>
              </Box>
              <Grid>
                <BenefitToAgentDataGrid
                  benefitToAgent={state.benefits}
                  onBenefitToAgentAdded={onBenefitToAgentAdded}
                  onBenefitToAgentChanged={onBenefitToAgentChanged}
                  onBenefitToAgentDeleted={onBenefitToAgentDeleted}
                />
              </Grid>
            </Box>
          </Box>
          <AutoSave
            onSubmit={onSubmit}
            defaultValues={state.agentAppointment}
          ></AutoSave>
        </FormWrapper>
      </form>
    </FormProvider>
  );
};

export default Authorisation;
