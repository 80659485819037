enum QuestionType {
  TEXT = "text",
  YES_NO = "yesno",
  YES_NO_NA = "yesno-na",
  FILE = "file",
}

interface Question {
  id: string
  question: string
  type: QuestionType
  label?: string
  notes?: string | string[]
  parent?: string
  condition?: string
}

interface Step {
  title: string
  questions: Question[]
}

const createQuestion = (
  id: string,
  question: string,
  type: QuestionType,
  options?: Partial<Omit<Question, "id" | "question" | "type">>,
): Question => ({
  id,
  question,
  type,
  ...options,
})

export const questionSteps: Step[] = [
  {
    title: "Seller and Contact Details",
    questions: [
      createQuestion(
        "sellerAndContactDetails_q1",
        "Will you be contactable for the entire duration of the transaction?",
        QuestionType.YES_NO,
        {
          notes: [
            "We must be able to contact you (and potentially meet with you) throughout the drafting process and when under contract, the conveyance.",
            "If you will be uncontactable during any known period prior to the settlement of this Contract (e.g., holidays), please provide details or relevant dates.",
          ],
        },
      ),
      createQuestion(
        "sellerAndContactDetails_q2",
        "Seller's dates of birth (if individuals)",
        QuestionType.TEXT,
      ),
      createQuestion(
        "sellerAndContactDetails_q3",
        "Is the Seller acting as Administrator or Executor of an Estate?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "sellerAndContactDetails_q3_child1",
        "If yes, is the Seller registered as the owner of the Property in that capacity?",
        QuestionType.YES_NO,
        {
          parent: "sellerAndContactDetails_q3",
          condition: "yes",
        },
      ),
      createQuestion(
        "sellerAndContactDetails_q3_child2",
        "If the Seller is not registered as owner of the Property, has the Seller obtained a grant of representation?",
        QuestionType.YES_NO,
        {
          parent: "sellerAndContactDetails_q3",
          condition: "yes",
        },
      ),
      createQuestion(
        "sellerAndContactDetails_q4",
        "Are the Sellers separated or divorced?",
        QuestionType.YES_NO_NA,
      ),
      createQuestion(
        "sellerAndContactDetails_q4_child1",
        "Is the sale part of a family law property settlement?",
        QuestionType.YES_NO,
        {
          parent: "sellerAndContactDetails_q4",
          condition: "yes",
        },
      ),
      createQuestion(
        "sellerAndContactDetails_q4_child2",
        "Is there an agreement or order in place (Court Order, Financial Agreement, or Consent Orders)?",
        QuestionType.YES_NO,
        {
          parent: "sellerAndContactDetails_q4",
          condition: "yes",
        },
      ),
      createQuestion(
        "sellerAndContactDetails_q4_child2_child",
        "Please provide a copy of the agreement or Order.",
        QuestionType.FILE,
        {
          parent: "sellerAndContactDetails_q4_child2",
          condition: "yes",
        },
      ),
      createQuestion(
        "sellerAndContactDetails_q5",
        "Are any of the Sellers bankrupt or insolvent, or is there a risk of bankruptcy or insolvency in the course of the transaction?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "sellerAndContactDetails_q6",
        "Does each Seller hold a valid clearance certificate?",
        QuestionType.YES_NO_NA,
      ),
      createQuestion(
        "sellerAndContactDetails_q6_child1",
        "We will send you instructions on how to get one.",
        QuestionType.YES_NO_NA,
        {
          parent: "sellerAndContactDetails_q6",
          condition: "no",
        },
      ),
      createQuestion(
        "sellerAndContactDetails_q6_child2",
        "Does each Seller hold a variation notice (if relevant)?",
        QuestionType.YES_NO_NA,
        {
          parent: "sellerAndContactDetails_q6",
          condition: "no",
        },
      ),
      createQuestion(
        "sellerAndContactDetails_q6_child3",
        "Please provide a copy of the clearance certificate.",
        QuestionType.FILE,
        {
          parent: "sellerAndContactDetails_q6",
          condition: "yes",
        },
      ),
      createQuestion(
        "sellerAndContactDetails_q7",
        "Is any individual Seller not an Australian Citizen or not permanent resident of Australia?",
        QuestionType.YES_NO_NA,
      ),
      createQuestion(
        "sellerAndContactDetails_q8",
        "Is any individual Seller not an Australian Citizen but holds a permanent residency status in Australia?",
        QuestionType.YES_NO_NA,
      ),
      createQuestion(
        "sellerAndContactDetails_q8_child1",
        "Please provide a copy of your VEVO visa",
        QuestionType.FILE,
        {
          parent: "sellerAndContactDetails_q8",
          condition: "yes",
        },
      ),
      createQuestion(
        "sellerAndContactDetails_q9",
        "Is a Seller acting through an attorney appointed under a Power of Attorney?",
        QuestionType.YES_NO_NA,
      ),
      createQuestion(
        "sellerAndContactDetails_q10",
        "Is the Seller selling a lot in a Community Titles Scheme e.g. unit, townhouse?",
        QuestionType.YES_NO_NA,
      ),
    ],
  },

  {
    title: "Use of Property",
    questions: [
      createQuestion(
        "property_description",
        "What is the Property currently being used for (e.g. investment property, residence, small business premises, hobby farm, or other use)?",
        QuestionType.TEXT,
      ),
      createQuestion(
        "property_priorDescription",
        "Are you aware of other uses of the Property prior to its current use?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "property_priorDescription_child",
        "Please provide details",
        QuestionType.TEXT,
        {
          parent: "property_priorDescription",
          condition: "yes",
        },
      ),
      createQuestion(
        "property_hasContamination",
        "Do you know if the Property may be affected by contamination (includeing from prior use or use on adjacent land)?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "property_hasContaminationDetails",
        "Please provide details",
        QuestionType.TEXT,
        {
          parent: "property_hasContamination",
          condition: "yes",
        },
      ),
      createQuestion(
        "property_hasSiteManagementPlan",
        "Do you know if the Property is subject to a site management plan?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "property_hasSiteManagementPlanDetails",
        "Please provide details",
        QuestionType.FILE,
        {
          parent: "property_hasSiteManagementPlan",
          condition: "yes",
        },
      ),
      createQuestion(
        "property_hasEnvironmentalAssessment",
        "Do you know if the Property has been the subject of an environmental assessment?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "property_hasEnvironmentalAssessmentDetails",
        "Please provide details",
        QuestionType.FILE,
        {
          parent: "property_hasEnvironmentalAssessment",
          condition: "yes",
        },
      ),
      createQuestion(
        "property_hasEnvironmentalNotices",
        "Are you aware of any notices, environmental evaluations, order or other documents that indicate that the land has been or is being used for a notifiable activity, is contaminated land or is being investigated for contamination issues?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "property_hasEnvironmentalNoticesDetails",
        "Please provide details",
        QuestionType.TEXT,
        {
          parent: "property_hasEnvironmentalNotices",
          condition: "yes",
        },
      ),
      createQuestion(
        "property_q1",
        "Are you aware if the land is on the contaminated land register or environmental management register?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "property_q1_details",
        "Please provide details",
        QuestionType.TEXT,
        {
          parent: "property_q1",
          condition: "yes",
        },
      ),
      createQuestion(
        "property_q2",
        "Are you the holder of an approval if a prescribed transitional environmental program?",
        QuestionType.YES_NO,
        {
          notes: [
            "If you are unsure about any of these questions, please call us. If you are selling a lot in a Community Titles Scheme, these questions also apply to scheme land and relevant notices may have been issued to the Body Corporate.",
          ],
        },
      ),
      createQuestion(
        "property_q2_details",
        "Please provide details",
        QuestionType.TEXT,
        {
          parent: "property_q2",
          condition: "yes",
        },
      ),
      createQuestion(
        "property_q3",
        "Do you have any plans for the Property before settlement that might be relevant in the conveyancing process (e.g. subdivision, installation of a pool, renting out the property)?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "property_q3_details",
        "Please provide details",
        QuestionType.TEXT,
        {
          parent: "property_q3",
          condition: "yes",
        },
      ),
      createQuestion(
        "property_q4",
        "Are there any entitlements that you believe attach to the Property (e.g. right of access, pontoon / jetty use / manrina / or similar, transferrable dwelling entitlements, infastructure offsets or credits)?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "property_q4_details",
        "Please provide details",
        QuestionType.TEXT,
        {
          parent: "property_q4",
          condition: "yes",
        },
      ),
      createQuestion(
        "property_q5",
        "Are there physical features which may impact upon the Buyer's use of the Property (e.g. manholes for sewer or private foul water line)?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "property_q5_details",
        "Please provide details",
        QuestionType.TEXT,
        {
          parent: "property_q5",
          condition: "yes",
        },
      ),
    ],
  },

  {
    title: "Property Disclosures",
    questions: [
      createQuestion(
        "q3_1",
        "Does the property compromise vacant land (not containing any buildings used for residential of commercial purposes)?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_1_child",
        "Is it intended to be used for residential purposes?",
        QuestionType.YES_NO,
        {
          parent: "q3_1",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_1_child2",
        "Can a residence be lawfully constructed on it?",
        QuestionType.YES_NO,
        {
          parent: "q3_1",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_2",
        "Does the Property comprise residential premises that have never been sold or subject to a long-term lease (50 years including options for renewal) as residential premises before?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_3",
        "Does the Property comprise residential premises that have been created by substantial renovations of a building?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_4",
        "Does the Property comprise residential premises that have been built to replace demolished premises on the same land?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_5",
        "Do you know of any unregistered encumbrances or interests affecting the Property such as sewerage or drainage easements, access rights for geothermal exploration or production or declaration of beach area?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_5_child",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_5",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_6",
        "Are you aware of any services on or under the Property which do not service the Property (i.e., services which related to a neighbouring property)?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_6_child",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_6",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_7",
        "Are you aware of any services for the benefit of the Property that pass through other land?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_7_child",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_7",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_8",
        "Are you aware of any claims or threatened claims in relation to the Property (e.g. claim by a third party to an interest in or right to use the Property)?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_8_child",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_8",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_9",
        "Are you aware of any reason why a person may intend to make a claim affecting the Property (e.g. unresolved disputes, outstanding debts, family law proceedings)?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_9_child",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_9",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_10",
        "Have you engaged in any recent transactions or dealings affecting the Property?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_10_child",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_10",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_11",
        "Are you aware of any interest or entitlement a person may have to lodge a caveat or priority notice in relation to the Property?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_11_child",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_11",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_12",
        "Have you received any of the following in relation to the Property a show cause or enforcement notice?",
        QuestionType.YES_NO,
      ),
      createQuestion("q3_12_child", "", QuestionType.FILE, {
        parent: "q3_12",
        condition: "yes",
      }),
      createQuestion(
        "q3_12_child2",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_12",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_13",
        "Have you received any of the following in relation to the Property communication from an authority that may lead to the issuing of a show cause or enforcement notice?",
        QuestionType.YES_NO,
      ),
      createQuestion("q3_13_child", "", QuestionType.FILE, {
        parent: "q3_13",
        condition: "yes",
      }),
      createQuestion(
        "q3_13_child2",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_13",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_14",
        "Have you received any of the following in relation to the Property a notice or order by an authority or court requiring work to be done or money spent?",
        QuestionType.YES_NO,
      ),
      createQuestion("q3_14_child", "", QuestionType.FILE, {
        parent: "q3_14",
        condition: "yes",
      }),
      createQuestion(
        "q3_14_child2",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_14",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_15",
        "Have you received any of the following in relation to the Property any other notices from an authority?",
        QuestionType.YES_NO,
      ),
      createQuestion("q3_15_child", "", QuestionType.FILE, {
        parent: "q3_15",
        condition: "yes",
      }),
      createQuestion(
        "q3_15_child2",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_15",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_16",
        "Are you aware of any significant earthworks on the Property (excavation or filling) that have altered the ground level of the Property?",
        QuestionType.YES_NO,
        {
          notes: [
            "If yes to any of the above, or you have given other documents or disclosures, please provide these to us. Please do not assume that the real estate agent has provided us with all documentation provided to you. These documents may affect the Buyer's or your rights and it is important that we receive copies of all documents you have given so that our advice is accurate.",
          ],
          label:
            "In some cases, earthworks will require a development approval (depending on the extent of any changes in ground levels). Unapproved or defective earthworks may provide the Buyer with a right to terminate.",
        },
      ),
      createQuestion(
        "q3_16_child2",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_16",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_17",
        "Is there a pool on the Property (including adjacent land used with the Property, a pool includes a spa or jacuzzi with 200 L or over)",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_18",
        "Is there a pool on the common property (including adjacent land used with the Property, a pool includes a spa or jacuzzi with 200 L or over)?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_19",
        "Do you have a Pool Safety Certificate?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_20",
        "Do you have an Exemption Certificate?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_21",
        "Do you have a notice that there is no Pool Safety Certificate?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_22",
        "Are you aware of any disputes, notices, applications or orders relating to dividing fences or trees?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_22_child",
        "Please provide a copy of the order",
        QuestionType.FILE,
        {
          parent: "q3_22",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_22_child2",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_22",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_23",
        "Are you aware of or have you signed any building or other covenants affecting the Property or are you part of an estate?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_23_child",
        "Please provide a copy of the covenants",
        QuestionType.FILE,
        {
          parent: "q3_23",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_23_child2",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_23",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_24",
        "Are you aware of any additional notices, such as development approval conditions (e.g. land use restrictions, occupancy restrictions, build height)?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_24_child",
        "Please provide a copy of relevant notices",
        QuestionType.FILE,
        {
          parent: "q3_24",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_24_child2",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_24",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_25",
        "Have you obtained a development approval for the Property?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_25_child",
        "Please provide a copy of relevant development approval",
        QuestionType.FILE,
        {
          parent: "q3_25",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_25_child2",
        "Have all development conditions (including for payment of any charges) been satisfied?",
        QuestionType.YES_NO,
        {
          parent: "q3_25",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_25_child2_child",
        "Please provide details of the outstanding development conditions",
        QuestionType.TEXT,
        {
          parent: "q3_25_child2",
          condition: "no",
        },
      ),
      createQuestion(
        "q3_26",
        "Are there any infrastructure agreements entered with the local government, any other government body or utility supplier?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_26_child",
        "Please provide a copy",
        QuestionType.FILE,
        {
          parent: "q3_26",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_27",
        "Has any building work been carried out on the Property by a person who is not licensed to carry out that building work?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_27_child",
        "Has the Property been offered for sale within six years of Yes that building work?",
        QuestionType.YES_NO,
        {
          parent: "q3_27",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_28",
        "Is there an electrical safety switch and compliant smoke alarm installed in the residence?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_29",
        "Are there any particular issues concerning the Property for which you require advice or particular searches to be undertaken or could become an issue for the Buyer (e.g. unapproved structures, non-compliant swimming pool fencing, flooding)?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_29_child",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_29",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_30",
        "Have you ever received a land tax assessment for the Property?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_30_child",
        "Please provide a copy of you latest land tax assesment.",
        QuestionType.FILE,
        {
          parent: "q3_30",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_31",
        "Do you have any concerns about the Property? Are there any issues we should be aware of?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_31_child",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_31",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_32",
        "Is there anything else we should know about the Property or the possible sale?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_32_child",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_32",
          condition: "yes",
        },
      ),
      createQuestion(
        "q3_33",
        "Do you require any special conditions in the Contract in addition to the standard conditions?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q3_33_child",
        "Please provide details:",
        QuestionType.TEXT,
        {
          parent: "q3_33",
          condition: "yes",
        },
      ),
    ],
  },

  {
    title: "Goods and Chattels",
    questions: [
      createQuestion(
        "q4_1",
        "Are any goods or chattels (personal property) included in the Property being sold? (Note: some items may be considered chattels despite appearing fixed such as solar panels, water tank/pump, large items temporarily stored on the land)?",
        QuestionType.YES_NO,
      ),
      createQuestion("q4_1_child", "What those items are;", QuestionType.TEXT, {
        parent: "q4_1",
        condition: "yes",
      }),
      createQuestion(
        "q4_1_child2",
        "If any items have a serial number (e.g. boats, cars etc.), the serial numbers;",
        QuestionType.TEXT,
        {
          parent: "q4_1",
          condition: "yes",
        },
      ),
      createQuestion(
        "q4_1_child2",
        "The amount of the purchase price being paid for those items.",
        QuestionType.TEXT,
        {
          parent: "q4_1",
          condition: "yes",
        },
      ),
      createQuestion(
        "q4_2",
        "Does you wish to exclude any fixtures that you do not want to be part of the sale?",
        QuestionType.YES_NO,
      ),
      createQuestion(
        "q4_2_child",
        "Please provide details:",
        QuestionType.TEXT,
        { parent: "q4_2", condition: "yes" },
      ),
      createQuestion(
        "q4_3",
        "Did you recieve finance to purchase the chattels?",
        QuestionType.YES_NO,
      ),
      createQuestion("q4_4", "Is the finance paid off?", QuestionType.YES_NO),
    ],
  },
]
