import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createWorkspaceState, selectedAgent } from "./create-workspace-slice";
import { User } from "../../landconnex-api-client";
import { Fragment } from "react/jsx-runtime";

const SelectAgent = () => {
  const state = useAppSelector(createWorkspaceState);
  const dispatch = useAppDispatch();
  const handleAgentChange = (_event: any, agent: User | null): void => {
    dispatch(selectedAgent(agent));
    // setAgentError(null);
  };
  return (
    <Fragment>
      <Typography variant="h5" my={2}>
        Agent
      </Typography>
      <Box sx={{ display: "flex", margin: "15px 0" }}>
        <Autocomplete
          disablePortal
          id="agent"
          fullWidth
          size="small"
          value={state.selectedAgent}
          onChange={handleAgentChange}
          options={state.users.map<User>((user) => ({
            id: user.sub,
            firstName: user.participant.firstName!,
            lastName: user.participant.lastName!,
          }))}
          getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Agent"
              variant="filled"
              // error={!!agentError}
              // helperText={agentError}
            />
          )}
        />
      </Box>
    </Fragment>
  );
};

export default SelectAgent;
