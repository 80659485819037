import { PayloadAction } from "@reduxjs/toolkit"
import { getInvoice } from "./thunks"
import { Invoice } from "../../landconnex-api-client"
import { createAppSlice } from "../../app/createAppSlice"

export enum ViewInvoiceStatus {
  Initial,
  Pending,
  Fulfilled,
}
export interface ViewInvoiceState {
  invoiceId: string | null
  status: ViewInvoiceStatus
  error: string | null
  invoice?: Invoice
}

const initialState: ViewInvoiceState = {
  invoiceId: null,
  status: ViewInvoiceStatus.Initial,
  error: null,
}

export const viewInvoiceSlice = createAppSlice({
  name: "viewInvoice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getInvoice.pending, state => {
      state.status = ViewInvoiceStatus.Pending
      state.error = null
    })
    builder.addCase(
      getInvoice.fulfilled,
      (state, action: PayloadAction<Invoice>) => {
        state.status = ViewInvoiceStatus.Fulfilled
        state.invoice = action.payload
      },
    )
  },
  selectors: {
    selectInvoice: viewInvoice => viewInvoice,
  },
})

export const {selectInvoice} = viewInvoiceSlice.selectors 


