import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Page from "../../components/page";
import { Box, InputAdornment, IconButton, OutlinedInput } from "@mui/material";
import { withAuthenticationRequired } from "react-oidc-context";
import SearchIcon from "@mui/icons-material/Search";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import SortByAlphaOutlinedIcon from "@mui/icons-material/SortByAlphaOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { singleLineAddress } from "../../helpers/helpers";
import WorkspaceContents from "./components/workspace-contents";
import LCCard from "../../components/cards/lc-card";
import RedirectToLogin from "../../components/redirect-to-login";
import { getWorkflowState, getWorkspaces, selectWorkspacesState, WorkspacesStatus } from "../state/workspaces/workspacesSlice";

const WorkspacesPage: FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const [isCardView, setIsCardView] = useState(true);
  const [sortType, setSortType] = useState<"default" | "asc" | "desc">(
    "default"
  );
  const [filterType, setFilterType] = useState<
    "default" | "address" | "recent"
  >("default");
  const state = useAppSelector(selectWorkspacesState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (state.status === WorkspacesStatus.initial) {
      dispatch(getWorkspaces());
    }
  }, [state.status, dispatch]);

  useEffect(() => {
    state.workspaces.forEach((w) => {
      dispatch(getWorkflowState({ workspaceId: w.id }));
    });
  }, [state.workspaces, dispatch]);

  const handleSortByCreateDate = () => {
    setFilterType("recent");
    setSortType("default");
  };

  const handleSortByAddress = () => {
    setFilterType("address");
    setSortType((prev) => {
      if (prev === "default") return "asc";
      if (prev === "asc") return "desc";
      return "default";
    });
  };

  const handleViewToggle = () => {
    setIsCardView((prev) => !prev);
  };

  const filteredWorkspaces = state.workspaces
    .filter((w) =>
      singleLineAddress(w.property)
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    )
    .sort((a, b) => {
      if (filterType === "recent") {
        return (
          new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
        );
      }
      if (filterType === "address") {
        if (sortType === "asc") {
          return singleLineAddress(a.property).localeCompare(
            singleLineAddress(b.property)
          );
        }
        if (sortType === "desc") {
          return singleLineAddress(b.property).localeCompare(
            singleLineAddress(a.property)
          );
        }
      }
      return 0;
    });

  return (
    <Page>
      <LCCard title={"Workspaces"} titleColor="#000" bgColor="#fff">
        <Box
          sx={{
            width: "100%",
            margin: "0 0 30px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: {
              xs: 2,
              md: 1,
            },
          }}
        >
          <OutlinedInput
            id="input-search-header"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search Address"
            sx={{ width: { xs: "100%", md: "500px" }, borderRadius: "10px" }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <Box>
            <IconButton
              onClick={handleSortByCreateDate}
              sx={{
                display: "inline-flex",
                background: "rgba(208, 226, 255, .5)",
                borderRadius: "10px",
                padding: { sm: "10px", xs: "7px" },
                marginRight: {
                  sm: 1,
                  xs: "5px",
                },
                cursor: "pointer",
              }}
            >
              <DateRangeOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={handleSortByAddress}
              sx={{
                display: "inline-flex",
                background: "rgba(208, 226, 255, .5)",
                borderRadius: "10px",
                padding: { sm: "10px", xs: "7px" },
                marginRight: {
                  sm: 1,
                  xs: "5px",
                },
                cursor: "pointer",
              }}
            >
              <SortByAlphaOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={handleViewToggle}
              sx={{
                display: "inline-flex",
                background: "rgba(208, 226, 255, .5)",
                borderRadius: "10px",
                padding: { sm: "10px", xs: "7px" },
                marginRight: {
                  sm: 1,
                  xs: "5px",
                },
                cursor: "pointer",
              }}
            >
              {isCardView ? (
                <FormatListBulletedOutlinedIcon />
              ) : (
                <DashboardCustomizeOutlinedIcon />
              )}
            </IconButton>
          </Box>
        </Box>

        <WorkspaceContents
          workspaces={filteredWorkspaces}
          isCardView={isCardView}
          workflowStates={state.workflowStates}
        />
      </LCCard>
    </Page>
  );
};

export default withAuthenticationRequired(WorkspacesPage, {
  OnRedirecting: () => <RedirectToLogin />,
  signinRedirectArgs: { redirect_uri: window.location.href },
});
