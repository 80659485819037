import { ActionReducerMapBuilder } from "@reduxjs/toolkit"
import { Participant1, TenantContact } from "../../landconnex-api-client"
import { getLicenceeDetails, getMyDetails, updateMyDetails } from "./thunks"
import { createAppSlice } from "../../app/createAppSlice"

export enum SettingsStatus {
  initial,
  loading,
  dirty,
  ready,
  updated,
}
export interface SettingsState {
  myDetails: Participant1
  myDetailsStatus: SettingsStatus
  licencee: TenantContact
  licenceeStatus: SettingsStatus
}
const initialState: SettingsState = {
  myDetails: {
    firstName: "",
    lastName: "",
    email: "",
    streetAddress1: "",
    locality: "",
    postCode: "",
    stateOrTerritory: "",
  },
  myDetailsStatus: SettingsStatus.initial,
  licencee: {
    contact: {
      firstName: "",
      lastName: "",
      email: "",
      streetAddress1: "",
      locality: "",
      postCode: "",
      stateOrTerritory: "",
      acn: "",
      abn: "",
    },
  },
  licenceeStatus: SettingsStatus.initial,
}
export const settingsSlice = createAppSlice({
  name: "settingsSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    getMyDetailsReducer(builder)
    licenceeReducers(builder)
  },
  selectors: {
    selectMyDetails: state => state.myDetails,
    selectMyDetailsStatus: state => state.myDetailsStatus,
    selectLicencee: state => state.licencee,
    selectLicenceeStatus: state => state.licenceeStatus,
  },
})

function getMyDetailsReducer(
  builder: ActionReducerMapBuilder<SettingsState>,
): void {
  builder.addCase(getMyDetails.fulfilled, (state, action) => {
    state.myDetails = action.payload
    state.myDetailsStatus = SettingsStatus.ready
  })
  builder.addCase(updateMyDetails.fulfilled, (state, action) => {
    state.myDetails = action.payload
    state.myDetailsStatus = SettingsStatus.updated
  })
}

function licenceeReducers(
  builder: ActionReducerMapBuilder<SettingsState>,
): void {
  builder.addCase(getLicenceeDetails.fulfilled, (state, action) => {
    state.licencee = action.payload
    state.licenceeStatus = SettingsStatus.ready
  })
}
export const {} = settingsSlice.actions
export const {
  selectLicencee,
  selectLicenceeStatus,
  selectMyDetails,
  selectMyDetailsStatus,
} = settingsSlice.selectors
