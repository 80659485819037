import { FC } from "react";
import { styled } from "@mui/material/styles";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import AccountAvatar from "./account-avatar";
import KonveiMiniDrawer from "./konvei-mini-drawer";
import Footer from "./footer";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { sessionState, toggleDrawer } from "../features/session/session-slice";
import logoImage from "../../public/landconnex-logo.svg";

export interface PageProps {
  title?: string;
  children?: React.ReactElement | React.ReactElement[];
  showBackNavigation?: boolean;
}

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: "-20px",
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    padding: theme.spacing(2),
  },
}));

const Page: FC<PageProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(sessionState);

  return (
    <Box sx={{ display: "flex", height: "100%", background: "#fff" }}>
      <AppBar
        position="fixed"
        component="nav"
        sx={{ background: "#fff", boxShadow: 0, zIndex: "1201" }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            aria-label="open drawer"
            onClick={() => dispatch(toggleDrawer())}
            sx={{
              mr: { sm: 2, xs: 0 },
              "&:hover": { backgroundColor: "#fff" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                background: "rgba(208, 226, 255, .5)",
                borderRadius: "10px",
                padding: { sm: "10px", xs: "7px" },
                marginRight: { sm: 1, xs: "5px" },
                cursor: "pointer",
              }}
            >
              <MenuIcon sx={{ color: "#003285" }} />
            </Box>
          </IconButton>
          <Box component="div" sx={{ flexGrow: 1 }}>
            <Box sx={{ display: "flex", width: 200 }}>
              <img
                src={logoImage}
                alt="LandConnex Logo"
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              background: "rgba(255, 127, 62, 0.2)",
              borderRadius: "10px",
              padding: { sm: "10px", xs: "7px" },
              marginRight: { sm: 1, xs: "5px" },
              cursor: "pointer",
            }}
          >
            <NotificationsNoneOutlinedIcon
              fontSize="small"
              sx={{ color: "#FF7F3E" }}
            />
          </Box>
          <Box
            sx={{
              background: "rgb(238, 242, 246)",
              borderRadius: "30px",
              padding: { sm: "0 7px", xs: "0 5px" },
            }}
          >
            <AccountAvatar
              email={state.details?.email!}
              firstName={state.details?.firstName}
              lastName={state.details?.lastName}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <KonveiMiniDrawer
        open={state.drawerOpen}
        toggleDrawer={() => dispatch(toggleDrawer())}
      />
      <Main
        open={state.drawerOpen}
        sx={{
          padding: {
            sm: "24px",
            xs: "20px 5px",
          },
        }}
      >
        <Box
          sx={{
            background: "#f0f3ff",
            borderRadius: { sm: "20px", xs: "15px" },
            marginTop: "45px",
            minHeight: "calc(-70px + 100vh)",
            padding: { sm: "10px", xs: "5px" },
          }}
        >
          {children}
        </Box>
        <Footer />
      </Main>
    </Box>
  );
};

export default Page;
