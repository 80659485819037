import {
  ActionReducerMapBuilder,
  PayloadAction,
} from "@reduxjs/toolkit";
import { completeSubscription, getTeam, startSubscription } from "./thunks";
import { CreateSubscriptionResponse, Team } from "../../landconnex-api-client";
import { createAppSlice } from "../../app/createAppSlice";

export enum CreateSubscriptionStatus {
  initial,
  pending,
  showingSubscriptionBenefits,
  creatingCustomer,
  collectingPayment,
  subscriptionCreated,
  complete,
}

export interface CreateSubscriptionState {
  status: CreateSubscriptionStatus;
  clientSecretID: string | undefined;
  customerID?: string;
  setupIntentID?: string;
  team?: Team;
}

const initialState: CreateSubscriptionState = {
  status: CreateSubscriptionStatus.initial,
  clientSecretID: undefined,
  customerID: undefined,
  setupIntentID: undefined,
};

export const createSubscriptionSlice = createAppSlice({
  name: "createSubscriptionSlice",
  initialState: initialState,
  extraReducers: (builder) => {
    startSubscriptionReducer(builder);
    completeSubscriptionReducer(builder);
    getTeamReducer(builder);
  },
  reducers: {
    createCustomer: (state) => {
      state.status = CreateSubscriptionStatus.creatingCustomer;
    },
  },
  selectors: {
    selectCreateSubscriptionState: state => state,
  }
});

function startSubscriptionReducer(
  builder: ActionReducerMapBuilder<CreateSubscriptionState>
): void {
  builder.addCase(startSubscription.pending, (state) => {
    state.status = CreateSubscriptionStatus.pending;
  });
  builder.addCase(
    startSubscription.fulfilled,
    (state, action: PayloadAction<CreateSubscriptionResponse>) => {
      state.setupIntentID = action.payload.setupIntentID;
      state.clientSecretID = action.payload.clientSecretID;
      state.status = CreateSubscriptionStatus.collectingPayment;
    }
  );
}

function getTeamReducer(
  builder: ActionReducerMapBuilder<CreateSubscriptionState>
): void {
  builder.addCase(getTeam.pending, (state) => {
    state.status = CreateSubscriptionStatus.pending;
  });
  builder.addCase(getTeam.fulfilled, (state, action: PayloadAction<Team>) => {
    state.team = action.payload;
    if (action.payload.subscriptionID) {
      state.status = CreateSubscriptionStatus.subscriptionCreated;
    } else {
      state.status = CreateSubscriptionStatus.showingSubscriptionBenefits;
    }
    // action.payload.subscriptionID
    //   ? (state.status = CreateSubscriptionStatus.subscriptionCreated)
    //   : CreateSubscriptionStatus.showingSubscriptionBenefits;
    //  state.status = CreateSubscriptionStatus.ready;
  });
}

function completeSubscriptionReducer(
  builder: ActionReducerMapBuilder<CreateSubscriptionState>
): void {
  builder.addCase(completeSubscription.pending, (state) => {
    state.status = CreateSubscriptionStatus.pending;
  });
  builder.addCase(completeSubscription.fulfilled, (state) => {
    state.status = CreateSubscriptionStatus.complete;
  });
}

export const { createCustomer } = createSubscriptionSlice.actions;
export const { selectCreateSubscriptionState } = createSubscriptionSlice.selectors;

