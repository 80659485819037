import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  
} from "@mui/x-data-grid";
import { BenefitToAgent } from "../../../landconnex-api-client";
import { OnBenefitToAgentChanged } from "../../../components/events";
import { Delete } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { australianCurrencyFormatter } from "../../../helpers/helpers";
import CurrencyDataGridEditComponent from "./currency-data-grid-edit-component";

export interface BenefitToAgentDataGridProps {
  benefitToAgent: Array<BenefitToAgent>;
  onBenefitToAgentChanged: OnBenefitToAgentChanged;
  onBenefitToAgentDeleted: OnBenefitToAgentChanged;
  onBenefitToAgentAdded: () => void;
}

const BenefitToAgentDataGrid: React.FC<BenefitToAgentDataGridProps> = ({
  benefitToAgent,
  onBenefitToAgentChanged,
  onBenefitToAgentDeleted,
}) => {
  const [gridWidth, setGridWidth] = useState(0);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const handleResize = (element: HTMLElement) => {
    setGridWidth(element.clientWidth);
  };

  useEffect(() => {
    // Initial column widths based on the percentages you want
    const initialColumns: GridColDef[] = [
      {
        field: "service",
        headerName: "Service",

        editable: true,
        width: gridWidth * 0.3,
      },
      {
        field: "source",
        headerName: "Source",

        editable: true,
        width: gridWidth * 0.3,
      },
      {
        field: "estimatedAmount",
        headerName: "Estimated Amount",
        renderCell: (p) => {
          return (
            <Typography>
              {p.value
                ? australianCurrencyFormatter.format(p.value / 100)
                : null}
            </Typography>
          );
        },
        renderEditCell: (p) => {
          return <CurrencyDataGridEditComponent {...p} />;
        },
        width: gridWidth * 0.2,
        editable: true,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: gridWidth * 0.1,
        cellClassName: "actions",
        getActions: (params) => {
          return [
            <GridActionsCellItem
              icon={<Delete />}
              label="Cancel"
              className="textPrimary"
              color="inherit"
              onClick={() =>
                onBenefitToAgentDeleted(params.row as BenefitToAgent)
              }
            />,
          ];
        },
      },
    ];
    setColumns(initialColumns);
  }, [gridWidth]);

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={benefitToAgent}
        rowSelection={false}
        editMode="row"
        hideFooter={true}
        slots={{
        //  toolbar:EditToolbar
          //toolbar: EditToolbar,
        }}
        slotProps={{
       //   toolbar: { onBenefitToAgentAdded: onBenefitToAgentAdded },
        }}
        columns={columns}
        processRowUpdate={(newRow) => {
          const b = newRow as BenefitToAgent;
          onBenefitToAgentChanged(b);
          return newRow;
        }}
        onProcessRowUpdateError={(e) => {
          console.log(e);
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
        onResize={() =>
          handleResize(
            document.querySelector(".MuiDataGrid-root") as HTMLElement
          )
        }
      />
    </Box>
  );
};

// interface EditToolbarProps {
//   onBenefitToAgentAdded: () => void;
//   setRowModesModel: (
//     newModel: (oldModel: GridRowModesModel) => GridRowModesModel
//   ) => void;
// }


export default BenefitToAgentDataGrid;
