import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit"
import {
  Annexure,
  Contract,
  Participant,
  Participant1RoleEnum,
  Property,
  WorkspaceSummary,
} from "../../landconnex-api-client"
import {
  addBuyer,
  addSeller,
  createContract,
  deleteBuyer,
  getContract,
  getCurrentUserDetails,
  getPdf,
  removeSeller,
  saveContract,
  saveContractParticipant,
  uploadAnnexure,
} from "./thunks"
// import { WritableDraft } from "immer/dist/internal/types";
import { toKebabCase } from "../../helpers/helpers"
import { createAppSlice } from "../../app/createAppSlice"

export enum ContractStepperStatus {
  initial,
  loading,
  ready,
  dirty,
  deleteBuyerFulfilled,
  pdfReady,
  annexureAdded,
}

export enum ContractPreviewStatus {
  initial,
  loading,
  ready,
}

interface ContractStepperState {
  status: ContractStepperStatus
  contractPreviewStatus: ContractPreviewStatus
  sellers: Array<Participant>
  sellerSolicitor: Participant
  sellerAgent: Participant
  buyers: Array<Participant>
  buyerSolicitor: Participant
  contract: Contract
  workspace: WorkspaceSummary
  property: Property
  url: string
  fileName: string
  annexures: Annexure[]
  step: string
  workspaceId: number
}

const initialState: ContractStepperState = {
  status: ContractStepperStatus.initial,
  contractPreviewStatus: ContractPreviewStatus.initial,
  sellers: [{ id: 0 }],
  sellerSolicitor: { id: 0 },
  sellerAgent: {},
  buyers: [],
  buyerSolicitor: {},
  workspaceId: 0,
  contract: {
    id: 0,
    status: "",
    hasEncumbrances: false,
    hasNeighbourhoodDisputes: false,
    hasPool: false,
    hasPoolCertificate: false,
    hasSafetySwitches: false,
    hasSellerSolicitor: false,
    hasSmokeAlarms: false,
    hasTenant: false,
  },
  workspace: {},
  property: {},
  url: "",
  fileName: "",
  annexures: [],
  step: "clients",
}
export const contractStepperSlice = createAppSlice({
  name: "contractStepper",
  initialState,
  reducers: {
    initialise: (state, action: PayloadAction<number>) => {
      state.status = ContractStepperStatus.initial
      state.step = "seller"
      state.sellers = []
      state.buyers = []
      state.buyerSolicitor = {}
      state.sellerSolicitor = {}
      state.workspaceId = action.payload
      state.contract = {
        id: 0,
        status: "",
        hasEncumbrances: false,
        hasNeighbourhoodDisputes: false,
        hasPool: false,
        hasPoolCertificate: false,
        hasSafetySwitches: false,
        hasSellerSolicitor: false,
        hasSmokeAlarms: false,
        hasTenant: false,
      }
    },
    moveToStep: (state, action: PayloadAction<string>) => {
      state.step = action.payload
    },
    updateSeller: (state, action: PayloadAction<Participant>) => {
      const index = state.sellers.findIndex(v => v.id == action.payload.id)
      state.sellers[index] = action.payload
      state.status = ContractStepperStatus.dirty
    },
    updateSellerSolicitor: (state, action: PayloadAction<Participant>) => {
      state.sellerSolicitor = action.payload
      state.status = ContractStepperStatus.dirty
    },
    updateSellerAgent: (state, action: PayloadAction<Participant>) => {
      state.sellerAgent = action.payload
      state.status = ContractStepperStatus.dirty
    },
    updateContract: (state, action: PayloadAction<Contract>) => {
      state.contract = action.payload
      state.status = ContractStepperStatus.dirty
    },
  },
  extraReducers: builder => {
    createContractReducer(builder)
    addSellerReducer(builder)
    addBuyerReducer(builder)
    removeSellerReducer(builder)
    deleteBuyerReducer(builder)
    getContractReducer(builder)
    getPdfReducer(builder)
    saveParticipantsReducer(builder)
    saveContractReducer(builder)
    uploadAnnexureReducer(builder)
    getCurrentUserDetailsReducer(builder)
  },
  selectors: {
    contractStepperState: contractStepper => contractStepper,
  },
})

function saveParticipantsReducer(
  builder: ActionReducerMapBuilder<ContractStepperState>,
) {
  builder.addCase(saveContractParticipant.fulfilled, state => {
    state.status = ContractStepperStatus.ready
    // switch (action.payload.role) {
    //   case Role.SellerSolicitor:
    //     break;
    //   case Role.Seller:
    //     state.sellers.splice(
    //       state.sellers.findIndex((x) => x.id == action.payload.id),
    //       1,
    //       action.payload
    //     );
    //     break;
    //   default:
    //     break;
    // }
  })
}

function getCurrentUserDetailsReducer(
  builder: ActionReducerMapBuilder<ContractStepperState>,
) {
  builder.addCase(getCurrentUserDetails.fulfilled, (state, action) => {
    const id = state.sellerSolicitor.id
    const participantType = state.sellerSolicitor.participantType
    const participantRole = state.sellerSolicitor.role
    state.sellerSolicitor = action.payload
    state.sellerSolicitor.id = id
    state.sellerSolicitor.participantType = participantType
    state.sellerSolicitor.role = participantRole

    state.status = ContractStepperStatus.ready
  })
}

function saveContractReducer(
  builder: ActionReducerMapBuilder<ContractStepperState>,
) {
  builder.addCase(saveContract.fulfilled, (state, action) => {
    state.contract = action.payload
    state.status = ContractStepperStatus.ready
  })
}

function addSellerReducer(
  builder: ActionReducerMapBuilder<ContractStepperState>,
) {
  builder.addCase(addSeller.fulfilled, (state, action) => {
    state.sellers.push({ id: action.payload, role: "seller" })
  })
}

function addBuyerReducer(
  builder: ActionReducerMapBuilder<ContractStepperState>,
) {
  builder.addCase(addBuyer.fulfilled, (state, action) => {
    state.buyers.push({ id: action.payload, role: "buyer" })
  })
}

function createContractReducer(
  builder: ActionReducerMapBuilder<ContractStepperState>,
) {
  builder.addCase(createContract.pending, state => {
    state.status = ContractStepperStatus.loading
  })
  builder.addCase(createContract.fulfilled, (state, action) => {
    state.contract = action.payload.contract
    state.sellerSolicitor = action.payload.solicitor
    state.sellers = [action.payload.seller]
    state.sellerAgent = action.payload.agent
    state.status = ContractStepperStatus.ready
  })
}

function getPdfReducer(builder: ActionReducerMapBuilder<ContractStepperState>) {
  builder.addCase(getPdf.fulfilled, (state, action) => {
    state.url = action.payload
    state.fileName = generateFileName(state)
    state.contractPreviewStatus = ContractPreviewStatus.ready
  })
  builder.addCase(getPdf.pending, state => {
    state.contractPreviewStatus = ContractPreviewStatus.loading
  })
}

function generateFileName(state: ContractStepperState): string {
  const name = `residential contract ${state.property.streetAddress1} ${state.property.locality} ${state.property.stateOrTerritory}`
  return toKebabCase(name)
}

function getContractReducer(
  builder: ActionReducerMapBuilder<ContractStepperState>,
) {
  builder.addCase(getContract.fulfilled, (state, action) => {
    state.contract = action.payload.contract
    state.sellers = action.payload.participants.filter(a => a.role == "seller")

    state.buyers = action.payload.participants.filter(a => a.role == "buyer")
    const sellerSolicitor = action.payload.participants.filter(
      a => a.role == "sellerSolicitor",
    )

    if (sellerSolicitor.length > 0) {
      state.sellerSolicitor = sellerSolicitor[0]
    }
    const buyerSolicitor = action.payload.participants.filter(
      a => a.role == "buyerSolicitor",
    )
    if (buyerSolicitor.length > 0) {
      state.buyerSolicitor = buyerSolicitor[0]
    }

    state.annexures = action.payload.annexures

    const agent = action.payload.participants.filter(
      a => a.role == Participant1RoleEnum.SellerAgent,
    )
    if (agent.length > 0) {
      state.sellerAgent = agent[0]
    }
    state.workspace = action.payload.workspace
    state.property = action.payload.workspace.property!
    state.annexures = action.payload.annexures
    state.status = ContractStepperStatus.ready
  })
}

function removeSellerReducer(
  builder: ActionReducerMapBuilder<ContractStepperState>,
) {
  builder.addCase(removeSeller.fulfilled, (state, action) => {
    state.sellers.splice(
      state.sellers.findIndex(a => a.id == action.payload),
      1,
    )
  })
}
function deleteBuyerReducer(
  builder: ActionReducerMapBuilder<ContractStepperState>,
) {
  builder.addCase(deleteBuyer.fulfilled, (state, action) => {
    state.buyers.splice(
      state.buyers.findIndex(a => a.id == action.payload),
      1,
    )
  })
}

function uploadAnnexureReducer(
  builder: ActionReducerMapBuilder<ContractStepperState>,
) {
  builder.addCase(uploadAnnexure.fulfilled, state => {
    state.status = ContractStepperStatus.annexureAdded
  })
}

export const {
  updateSeller,
  updateSellerSolicitor,
  updateContract,
  updateSellerAgent,
  initialise,
  moveToStep,
} = contractStepperSlice.actions

export default contractStepperSlice.reducer
export const { contractStepperState } = contractStepperSlice.selectors
// export const contractStepperState = (state: RootState) => state.contractStepper;
