import { FC, useEffect } from "react"
import { useForm, SubmitHandler, FormProvider } from "react-hook-form"
import { Box, Grid, Typography } from "@mui/material"
import AddressLabel from "../../../components/address-label"
import FormInputText from "../../../components/form-input-text"

import type { AgentAppointment, Property } from "../../../landconnex-api-client"
import { OnAgentAppointmentChanged } from "../../../components/events"
import LCCard from "../../../components/cards/lc-card"
import FormWrapper from "./form-wrapper"
import { useParams } from "react-router-dom"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import property from "../../contract-stepper/components/property"

import { saveProperty } from "../thunks"
import AutoSave from "../../../components/auto-save"
import { agentAppointmentState } from "../agent-appointment-slice"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

export type OnPropertyChanged = (property: Property) => void

// const presentUseOptions = [
//   "Vacant land",
//   "Dwelling",
//   "Multi Unit",
//   "Flats",
//   "Guest House Private Hotel",
//   "Farming",
//   "Industrial",
//   "Commercial",
//   "Other",
// ];

export interface PropertyProps {
  property: Property
  agentAppointment: AgentAppointment
  onPropertyChanged: OnPropertyChanged
  onAgentAppointmentChanged: OnAgentAppointmentChanged
}

const Property: FC = () => {
  const state = useAppSelector(agentAppointmentState)
  const params = useParams()
  const dispatch = useAppDispatch()
  const workspaceId = Number(params.workspaceId)

  const propertySchema = yup.object<Property>({
    lot: yup.string().required(),
    plan: yup.string().required(),
    titleReference: yup.string().required(),
  })

  const propertyFormMethods = useForm<Property>({
    defaultValues: state.property,
    resolver: yupResolver(propertySchema),
  })
  const {
    handleSubmit: propertyHandleSubmit,
    control: propertyControl,
    reset: propertyReset,
    trigger: propertyTrigger,
  } = propertyFormMethods

  useEffect(() => {
    propertyReset(state.property)
    propertyTrigger()
  }, [property])

  const onPropertySubmit: SubmitHandler<Property> = data => {
    dispatch(saveProperty({ property: data, workspaceId: workspaceId }))
  }

  const notes = [
    {
      title: "Note:",
      items: [
        "Please provide details of the property, land, or business as appropriate.",
        "Annexures detailing multiple properties may be attached if required.",
      ],
    },
  ]

  return (
    <FormProvider {...propertyFormMethods}>
      <form onSubmit={propertyHandleSubmit(onPropertySubmit)}>
        <FormWrapper
          title="Details of property or business that is to be sold, let, purchased, or managed"
          notes={notes}
        >
          <Box
            sx={{
              maxWidth: "1024px",
              margin: "15px auto",
              padding: "30px",
              background: "#faf7f7",
              borderRadius: "30px",
              boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid container spacing={2}>
              <Box
                sx={{
                  width: "500px",
                }}
              >
                <LCCard
                  title={"Property Address"}
                  titleColor="#000"
                  txtColor="#000"
                  bgColor="#CDC5B4"
                  circleElementBefore="#8f8c87"
                  circleElementAfter="#dfd5bf"
                >
                  <AddressLabel property={state.property} />
                </LCCard>
              </Box>

              <Grid item xs={12} sm={12} lg={12}>
                <Typography variant="h5">Description</Typography>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={6} sm={4}>
                <FormInputText
                  control={propertyControl}
                  name="lot"
                  label="Lot"
                  required
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormInputText
                  control={propertyControl}
                  name="plan"
                  label="Plan"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormInputText
                  control={propertyControl}
                  name="titleReference"
                  label="Title Reference"
                  required
                />
              </Grid>
            </Grid>
          </Box>
        </FormWrapper>
        <AutoSave
          onSubmit={onPropertySubmit}
          defaultValues={state.property}
        ></AutoSave>
      </form>
    </FormProvider>
  )
}

export default Property
