import { RootState } from "../../app/store";
import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { Participant } from "../../landconnex-api-client";
import { setSession } from "./thunks";

export enum SessionStatus {
  initial,
  loading,
  ready,
}

export interface SessionState {
  status: SessionStatus;
  details?: Participant;
  modalOpen: boolean;
  drawerOpen: boolean;
  /**
   * true if the user can create new workspaces; false otherwise.
   */
  isWorkspaceCreator: boolean;
  isWorkspaceAdministrator: boolean;

}

const initialState: SessionState = {
  status: SessionStatus.initial,
  modalOpen: false,
  drawerOpen: false,
  isWorkspaceCreator: false,
  isWorkspaceAdministrator: false,
};
export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    toggleModal: (state) => {
      state.modalOpen = !state.modalOpen;
    },
    toggleDrawer: (state) => {
      state.drawerOpen = !state.drawerOpen;
    },
  },
  extraReducers: (builder) => {
    setSessionReducers(builder);
  },
});

const setSessionReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(setSession.fulfilled, (state, action) => {
    state.details = action.payload.participant;

    state.isWorkspaceCreator = action.payload.roles.some((role) => {
      return role == "workspace_creator";
    });

    state.isWorkspaceAdministrator = action.payload.roles.some((role) => {
      return role == "workspace_administrator";
    });

    state.status = SessionStatus.ready;
  });
};

export const { toggleModal, toggleDrawer } = sessionSlice.actions;
export default sessionSlice.reducer;
export const sessionState = (state: RootState) => state.session;
