import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { getDocument } from "./thunks";
import { createAppSlice } from "../../app/createAppSlice";

export enum ViewDocumentStatus {
  initial,
  loading,
  ready,
}

export interface ViewDocumentState {
  status: ViewDocumentStatus;
  url: string;
}

const initialState: ViewDocumentState = {
  status: ViewDocumentStatus.initial,
  url: "",
};

export const viewDocumentSlice = createAppSlice({
  name: "viewDocument",
  initialState,
  reducers: {},
  extraReducers(builder) {
    getDocumentReducers(builder);
  },
  selectors: {
    selectViewDocumentState: viewDocument => viewDocument,
  },
});

function getDocumentReducers(
  builder: ActionReducerMapBuilder<ViewDocumentState>
) {
  builder.addCase(getDocument.pending, (state) => {
    state.status = ViewDocumentStatus.loading;
  });
  builder.addCase(getDocument.fulfilled, (state, action) => {
    console.log(action.payload);
    state.url = action.payload;
    state.status = ViewDocumentStatus.ready;
  });
}

export const {} = viewDocumentSlice.actions;
export const { selectViewDocumentState } = viewDocumentSlice.selectors;


