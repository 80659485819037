import { createAsyncThunk } from "@reduxjs/toolkit";
import { offersApi, workspacesApi } from "../../api";
import { OfferDetail, Workflow, WorkspaceSummary } from "../../landconnex-api-client";

export const getOffers = createAsyncThunk<OfferDetail[], number>(
  "offers/getOffers",
  async (workspaceId) => {
    return (await offersApi.getOffers(workspaceId)).data.items!;
  }
);

export const createOffer = createAsyncThunk<number, number>(
  "offers/createOffer",
  async (workspaceId) => {
    const newEntity = await offersApi.postOffer(workspaceId, {
      id: 0,
      hasEncumbrances: false,
      hasPool: false,
      hasPoolCertificate: false,
      hasSafetySwitches: false,
      hasSmokeAlarms: false,
      hasNeighbourhoodDisputes: false,
      hasTenant: false,
      status: "",
      hasSellerSolicitor: false,
    });
    return newEntity.data.id!;
  }
);

export const getWorkspace = createAsyncThunk<WorkspaceSummary, number>(
  "offers/getWorkspace",
  async (workspaceId) => {
    return (await workspacesApi.getWorkspace(workspaceId)).data;
  }
);

export const getWorkflow = createAsyncThunk<Workflow, number>(
  "offers/getWorkflow",
  async (workspaceId) => {
    return (await workspacesApi.getWorkflow(workspaceId)).data;
  }
);
