import { useParams } from "react-router-dom";
import Page from "../../components/page";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getInvoice } from "./thunks";
import { FC, useEffect } from "react";
import { selectInvoice, ViewInvoiceStatus } from "./view-invoice-slice";
import { InvoiceLineItem1 } from "../../landconnex-api-client";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";

const ViewInvoice = () => {
  const p = useParams();
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectInvoice);

  useEffect(() => {
    const workspaceId = parseInt(p.workspaceId!);
    dispatch(getInvoice({ workspaceId }));
  }, [dispatch, p.WorkspaceId]);
  return (
    <Page>
      <h1>View Invoice</h1>
      {state.status == ViewInvoiceStatus.Pending ? <>Loading</> : <></>}
      {state.status == ViewInvoiceStatus.Fulfilled ? (
        <InvoiceItems invoiceItems={state.invoice?.lineItems!} />
      ) : (
        <></>
      )}
    </Page>
  );
};

interface InvoiceItemsProps {
  invoiceItems: InvoiceLineItem1[];
}
const InvoiceItems: FC<InvoiceItemsProps> = ({ invoiceItems }) => {
  const totalAmount = invoiceItems.reduce(
    (total, item) => total + item.amount,
    0
  );
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceItems.map((row) => (
            <TableRow
              key={row.description}
              // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{row.description}</TableCell>
              <TableCell align="right">{row.quantity}</TableCell>
              <TableCell align="right">
                ${(row.amount / 100).toFixed(2)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={2} align="right">
              Total
            </TableCell>
            <TableCell align="right">
              ${(totalAmount / 100).toFixed(2)}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default ViewInvoice;
