import React, { useEffect } from "react";
import {
  agentAppointmentState,
  AgentAppointmentStatus,
  initialise,
  moveToStep,
} from "./agent-appointment-slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { getAgentAppointment } from "./thunks";

import { Box } from "@mui/material";
import KonveiStepper from "../../components/konvei-stepper";
import LCCard from "../../components/cards/lc-card";

const AgentAppointmentPage: React.FC = () => {
  const state = useAppSelector(agentAppointmentState);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  //const [setErrors] = useState<{ message: string; step: number }[]>([]);
  const steps = [
    "client",
    "agent",
    "property",
    "appointment",
    "sale",
    "commission",
    "authorisation",
    "review",
  ];

  const workspaceId = parseInt(params["workspaceId"] as string);
  const s = state.status;
  useEffect(() => {
    if (workspaceId != state.workspaceId) {
      dispatch(initialise(workspaceId));
    }
    switch (s) {
      case AgentAppointmentStatus.initial:
        dispatch(getAgentAppointment(workspaceId));
        break;

      default:
        break;
    }
  }, [workspaceId, dispatch]);

  function onStepChanged(step: string): void {
    dispatch(moveToStep(step));

    navigate(step);
  }

  return (
    <LCCard
      title={`Agent Appointment`}
      txtColor="#000"
      titleColor="#000"
      bgColor="#fff"
      sxCard={{ overflow: "visible" }}
    >
      {steps.indexOf(state.step) < 9 ? (
        <Box
          sx={{
            position: "sticky",
            background: "#fff",
            paddingBottom: "1px",
            top: 68,
            zIndex: 1000,
          }}
        >
          <KonveiStepper
            steps={steps}
            activeStep={state.step}
            onStepChanged={onStepChanged}
            isCompressed={true}
          />
        </Box>
      ) : (
        <></>
      )}

      <Outlet></Outlet>
    </LCCard>
  );
};

export default AgentAppointmentPage;
