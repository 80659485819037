import {
  Grid,
  Typography,
  Box,
  useTheme,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import AddressLabel from "../../../components/address-label";
import type { Property } from "../../../landconnex-api-client";
import LCCard from "../../../components/cards/lc-card";
import FormWrapper from "../../agent-appointment/components/form-wrapper";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { contractStepperState } from "../contract-stepper-slice";
import { saveProperty } from "../thunks";
import AutoSave from "../../../components/auto-save";
import FormInputText from "../../../components/form-input-text";

const presentUseOptions = [
  "Vacant land",
  "Dwelling",
  "Multi Unit",
  "Flats",
  "Guest House Private Hotel",
  "Farming",
  "Industrial",
  "Commercial",
  "Other",
];

interface BuiltOnType {
  isBuiltOn: string;
  description: string;
}
const isBuiltOnOptions = Array<BuiltOnType>();

isBuiltOnOptions.push({ description: "Built On", isBuiltOn: "true" });
isBuiltOnOptions.push({ description: "Vacant", isBuiltOn: "false" });
const holdingTypeOptions = ["freehold", "leasehold"];

const PropertyStep: React.FC = ({}) => {
  const state = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();
  const params = useParams();
  const workspaceId = Number(params.workspaceId);
  const disableField = ["buyer", "buyerSolicitor"].includes(
    state.workspace.workspace?.role!
  );

  const theme = useTheme();

  // const propertySchema = yup
  //   .object<Property>({
  //     lot: yup.string().required(),
  //     plan: yup.string().required(),
  //   })
  //   .required();

  const propertyFormMethods = useForm<Property>({
    defaultValues: state.property,
    //  resolver: yupResolver(propertySchema),
  });

  const { control, handleSubmit } = propertyFormMethods;

  const onPropertyChangedSubmit = (data: Property) => {
    dispatch(saveProperty({ workspaceId: workspaceId, property: data }));
  };

  return (
    <FormProvider {...propertyFormMethods}>
      <form onSubmit={handleSubmit(onPropertyChangedSubmit)}>
        <FormWrapper title="Property">
          <Box
            sx={{
              maxWidth: "1024px",
              margin: "15px auto",
              padding: "30px",
              background: "#faf7f7",
              borderRadius: "30px",
              boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid container spacing={2}>
              <Box
                sx={{
                  width: "500px",
                }}
              >
                <LCCard
                  title={"Property Address"}
                  titleColor="#000"
                  txtColor="#000"
                  bgColor="#CDC5B4"
                  circleElementBefore="#8f8c87"
                  circleElementAfter="#dfd5bf"
                >
                  <AddressLabel property={state.property} />
                </LCCard>
              </Box>

              <Grid container spacing={2} marginTop={2}>
                <Grid item xs={6} sm={4}>
                  <FormInputText
                    control={control}
                    name="lot"
                    label="Lot"
                    required
                    disabled={disableField}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <FormInputText
                    control={control}
                    name="plan"
                    label="Plan"
                    required
                    disabled={disableField}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormInputText
                    control={control}
                    name="titleReference"
                    label="Title Reference"
                    required
                    disabled={disableField}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h6" color={theme.palette.text.secondary}>
                    Description
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Typography variant="body1">
                    Is the land built on or vacant?
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Controller
                    control={control}
                    name="isBuiltOn"
                    render={() => (
                      <Select
                        variant="filled"
                        fullWidth
                        label="Is Built On"
                        disabled={disableField}
                        defaultValue={
                          state.property.isBuiltOn ? "true" : "false"
                        }
                        onChange={({ target }) => {
                          const prop = { ...state.property };
                          prop.isBuiltOn = target.value == "true";
                          onPropertyChangedSubmit(prop);
                        }}
                        // {...field}
                      >
                        {isBuiltOnOptions.map((option) => (
                          <MenuItem
                            key={option.description}
                            value={option.isBuiltOn}
                          >
                            {option.description}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Typography variant="body1">
                    What is the holding type?
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                  <Controller
                    control={control}
                    name="holdingType"
                    render={({ field }) => (
                      <Select
                        variant="filled"
                        fullWidth
                        label="Holding Type"
                        disabled={disableField}
                        {...field}
                      >
                        {holdingTypeOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2} lg={6}>
                  <Controller
                    control={control}
                    name="area"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Area"
                        variant="filled"
                        fullWidth
                        disabled={disableField}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <Controller
                    control={control}
                    name="presentUse"
                    render={({ field }) => (
                      <Select
                        variant="filled"
                        required
                        fullWidth
                        {...field}
                        label="Present Use"
                        disabled={disableField}
                      >
                        {presentUseOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <Controller
                    name="lga"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Local Government"
                        variant="filled"
                        required
                        fullWidth
                        disabled={disableField}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <AutoSave
              onSubmit={onPropertyChangedSubmit}
              defaultValues={state.property}
            ></AutoSave>
          </Box>
        </FormWrapper>
      </form>
    </FormProvider>
  );
};

export default PropertyStep;
