import { PayloadAction } from "@reduxjs/toolkit"
import { createAppSlice } from "../../app/createAppSlice"

interface WorkspaceEventsState {
  socket: WebSocket | null
  events: any[] // Stores events received from WebSocket
}

const initialState: WorkspaceEventsState = {
  socket: null,
  events: [],
}

export const workspaceEventsSlice = createAppSlice({
  name: "workspaceEvents",
  initialState,
  reducers: {
    connect: (state, action: PayloadAction<string>) => {
      //   const clientID = action.payload;
      state.socket = new WebSocket(
        `ws://events.api.landconnex.com.au/ws?workspace-id=${action.payload}`,
      )
      state.socket.addEventListener("open", event => {
        console.log("connection established", event)
      })

      //   state.socket.onopen = () => {
      //     console.log("WebSocket connected");

      //     if (state.socket) {
      //       state.socket.send("345"); // Send the client ID to the server
      //     }
      //   };

      state.socket.onmessage = event => {
        // Parse and store incoming events
        const messageData = event.data
        state.events.push(messageData)
      }

      //   state.socket.onclose = () => {
      //     console.log("WebSocket disconnected");
      //     state.socket = null;
      //   };

      //   state.socket.send("dfg");
    },
  },
  selectors: {
    selectWorkspaceEvents: state => state.events,
  },
})

export const { connect } = workspaceEventsSlice.actions
export const { selectWorkspaceEvents } = workspaceEventsSlice.selectors
