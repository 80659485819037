import {
  Participant,
  ParticipantParticipantTypeEnum,
  Property,
  Role,
} from "../landconnex-api-client";

function toKebabCase(input: string): string {
  return input
    .trim()
    .replace(/[^\w\s]/g, "")
    .replace(/\s+/g, "-")
    .toLowerCase();
}

function convertCamelCase(camelCaseString: string): string {
  return camelCaseString
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^./, (str) => str.toUpperCase());
}

function currencyFromBaseUnit(value?: number | string): number | undefined {
  if (!value) return undefined;

  const numericValue = typeof value === "string" ? parseInt(value) : value;
  return numericValue / 100;
}

function currencyToBaseUnit(value: number | undefined): number | undefined {
  if (!value) return undefined;
  return Math.floor(value! * 100);
}

const australianCurrencyFormatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

const participantName = (participant: Participant) => {
  switch (participant.participantType) {
    case ParticipantParticipantTypeEnum.Individual:
      return `${participant.firstName} ${participant.lastName}`;
    case ParticipantParticipantTypeEnum.Organisation:
      return participant.organisationName;
    case ParticipantParticipantTypeEnum.Trust:
      return `${participant.organisationName} as Trustee`;
    default:
      break;
  }
};

const getParticipantsByRole = (
  participants: Array<Participant>,
  role: Role
) => {
  return participants.filter((p) => p.role == role);
};

function capitalizeWords(s: string): string {
  return s
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function getEnumFromString<T extends Record<string, string>>(
  enumObject: T,
  str: string
): T[keyof T] | undefined {
  const value = Object.values(enumObject).find(
    (enumValue) => enumValue === str
  );
  return value as T[keyof T] | undefined;
}

const singleLineAddress = (property: Property): string => {
  return capitalizeWords(`${property.streetAddress1}, ${property.locality}`);
};

const singleLineFullAddress = (property: Property): string => {
  return capitalizeWords(`${property.streetAddress1}, 
    ${property.locality} ${property.stateOrTerritory}, 
    ${property.postCode}`);
};

const fullName = (participant: Participant): string => {
  switch (participant.participantType) {
    case "individual":
      if (
        participant.middleNames != undefined &&
        participant.middleNames != ""
      ) {
        return `${participant.firstName} ${participant.middleNames} ${participant.lastName}`;
      }
      return `${participant.firstName} ${participant.lastName}`;
    case "organisation":
    case "trust":
      return `${participant.organisationName}`;
    default:
      break;
  }
  return "";
};

const cleanAndCapitalize = (str: string): string => {
  return str
    .toLowerCase()
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const formatDate = (date?: string): string => {
  if (!date) {
    return "";
  }

  const formatter = new Intl.DateTimeFormat("en-AU");
  return formatter.format(new Date(date));
};

const formatDateAndTime = (date?: string, withTime?: boolean): string => {
  if (!date) {
    return "";
  }

  const formatter = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const parts = formatter.formatToParts(new Date(date));

  const formattedDate = `${
    parts.find((part) => part.type === "month")?.value
  } ${parts.find((part) => part.type === "day")?.value}, ${
    parts.find((part) => part.type === "year")?.value
  }`;
  const formattedTime = `${parts.find((part) => part.type === "hour")?.value}:${
    parts.find((part) => part.type === "minute")?.value
  }`;

  return `${formattedDate} ${withTime ? `at ${formattedTime}` : ""}`;
};

export {
  toKebabCase,
  currencyFromBaseUnit,
  currencyToBaseUnit,
  australianCurrencyFormatter,
  participantName,
  getEnumFromString,
  capitalizeWords,
  singleLineAddress,
  singleLineFullAddress,
  fullName,
  cleanAndCapitalize,
  formatDate,
  formatDateAndTime,
  getParticipantsByRole,
  convertCamelCase,
};
