import "./App.css"
import { Provider } from "react-redux"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"
import { store } from "./app/store"
import { Box, ThemeProvider, createTheme } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/en-au"
import AgentAppointmentPage from "./features/agent-appointment/agent-appointment-page"
import WorkspacePage from "./features/workspace/workspace-page"
// import WorkspacesPage from "./features/workspaces/workspaces-page";
// import AcceptInvitation from "./features/accept-invitation/accept-invitation";
// import ViewDocument from "./features/view-document/view-document";
// // import RequireAuth from "./require-auth";
// import SettingsPage from "./features/settings/settings-page";
// import MyDetails from "./features/settings/components/my-details";
// import LandingPage from "./features/landing/landing-page";
// import ManageUsers from "./features/manage-users";
import ContractStepperPage from "./features/contract-stepper/contract-stepper-page"
import SellerDetails from "./features/contract-stepper/components/seller-details"
import SellerSolicitor from "./features/contract-stepper/components/seller-solicitor"
import SellerAgent from "./features/contract-stepper/components/seller-agent"
import PropertyStep from "./features/contract-stepper/components/property"
import Encumbrances from "./features/contract-stepper/components/encumbrances"
import PoolSafety from "./features/contract-stepper/components/pool-safety"
import SpecialConditions from "./features/contract-stepper/components/special-conditions"
import NeighbourhoodDisputes from "./features/contract-stepper/components/neighbourhood-disputes"
import Annexures from "./features/contract-stepper/components/annexures"
import SafetyAlarmsAndSwitches from "./features/contract-stepper/components/safety-alarms-and-switches"
import Review from "./features/contract-stepper/components/review"
import ClientDetails from "./features/agent-appointment/components/client-details"
import LicenceeDetails from "./features/agent-appointment/components/licencee-details"
import Property from "./features/agent-appointment/components/property"
import Appointment from "./features/agent-appointment/components/appointment"
import PropertySale from "./features/agent-appointment/components/property-sale"
import Commission from "./features/agent-appointment/components/commission"
import Authorisation from "./features/agent-appointment/components/authorisation"
import Generate from "./features/agent-appointment/components/generate"
import Offers from "./features/offers"
import BuyersDetails from "./features/contract-stepper/components/buyers-details"
import Price from "./features/contract-stepper/components/price"
import BuyersSolicitor from "./features/contract-stepper/components/buyers-solicitor"
import Finance from "./features/contract-stepper/components/finance"
import Settlement from "./features/contract-stepper/components/settlement"
import Login from "./features/login"
import AuthCallback from "./features/auth-callback/auth-callback"
import LandingPage from "./features/landing/landing-page"
import CreateWorkspacePage from "./features/create-workspace/create-workspace-page"
import WorkspacesPage from "./features/workspaces/workspaces-page"
import ViewInvoice from "./features/view-invoice/view-invoice"
import AcceptInvitation from "./features/accept-invitation/accept-invitation"
import ViewDocument from "./features/view-document/view-document"
// import OfferDraftView from "./features/offer-draft/offer-draft-page";
// import AuthCallback from "./features/auth-callback/auth-callback";
// import Account from "./features/settings/components/account";
// import CreateSubscription from "./features/create-subscription/create-subscription";
import { loadStripe } from "@stripe/stripe-js"
import CreateSubscription from "./features/create-subscription/create-subscription"
import CreateSubscriptionSuccess from "./features/create-subscription/create-subscription-success"
import Questionnaire from "./features/questionnaire"
import OpenHouse from "./features/open-house/open-house";
// import CreateSubscriptionSuccess from "./features/create-subscription/create-subscription-success";
// import Login from "./features/login";
// import CreateWorkspacePage from "./features/create-workspace/create-workspace-page";
// import ViewInvoice from "./features/view-invoice/view-invoice";

const stripePromise = loadStripe(
  "pk_test_51Q2gQE2XYepys3WSbAsDD4TNq7366t0IppX7cARGYsw04pDaFj7RnC1YBXhEuPOrMAOYqafvAOWcZbby9YDZdnvp00xYlZ9bTK",
)

const router = createBrowserRouter(
  [
    { path: "login", element: <Login /> },
    { path: "/questionnaire", element: <Questionnaire /> },
    {
      path: "/auth-callback",
      element: <AuthCallback />,
    },
    {
      path: "/subscriptions",
      element: <CreateSubscription stripePromise={stripePromise!} />,
    },
    {
      path: "/subscriptions/complete",
      element: <CreateSubscriptionSuccess />,
    },
    {
      path: "/",
      element: <LandingPage />,
      id: "landingPage",
    },
    // {
    //   path: "on-boarding",
    //   element: <OnboardingPage />,
    //   children: [
    //     {
    //       path: "",
    //       element: <Navigate to={"payment-details"} />,
    //     },
    //     {
    //       path: "payment-details",
    //       id: "payment-details",
    //       element: <PaymentDetails />,
    //     },
    //   ],
    // },

    {
      path: "/workspaces",
      element: <WorkspacesPage />,

      id: "workspaces",
    },
    {
      path: "/create-workspace",
      element: <CreateWorkspacePage />,

      id: "create-workspaces",
    },
    {
      path: "open-house/:workspaceId",
      element: <OpenHouse/>,
    },
    {
      path: "/workspaces/:workspaceId/invoice",
      element: <ViewInvoice />,
    },
    {
      path: "/workspaces/:workspaceId",
      element: <WorkspacePage />,
      id: "view-workspace",
      children: [
        {
          path: "contract",
          element: <ContractStepperPage />,
          children: [
            {
              path: "",
              element: <Navigate to="sellers" />,
            },
            {
              path: "sellers",
              id: "contract-seller",
              element: <SellerDetails />,
            },
            {
              path: "agent",
              element: <SellerAgent />,
            },
            {
              path: "solicitor",
              element: <SellerSolicitor />,
            },
            {
              path: "property",
              element: <PropertyStep />,
            },
            {
              path: "special-conditions",
              element: <SpecialConditions />,
            },
            {
              path: "encumbrances",
              element: <Encumbrances />,
            },
            {
              path: "pool-safety",
              element: <PoolSafety />,
            },
            {
              path: "safety-switches-and-alarms",
              element: <SafetyAlarmsAndSwitches />,
            },
            {
              path: "neighbourhood-disputes",
              element: <NeighbourhoodDisputes />,
            },
            {
              path: "annexures",
              element: <Annexures />,
            },
            {
              path: "review",
              element: <Review />,
            },
          ],
        },
        {
          path: "agent-appointment",
          element: <AgentAppointmentPage />,
          children: [
            {
              path: "",
              element: <Navigate to="client" />,
            },
            {
              path: "client",
              id: "client",
              element: <ClientDetails />,
            },
            {
              path: "agent",
              id: "agent",
              element: <LicenceeDetails />,
            },
            {
              path: "property",
              id: "property",
              element: <Property />,
            },

            {
              path: "appointment",
              id: "appointment",
              element: <Appointment />,
            },
            {
              path: "sale",
              id: "sale",
              element: <PropertySale />,
            },
            {
              path: "commission",
              id: "commission",
              element: <Commission />,
            },
            {
              path: "authorisation",
              id: "authorisation",
              element: <Authorisation />,
            },
            {
              path: "review",
              id: "review",
              element: <Generate />,
            },
          ],
        },
        {
          path: "offers",
          element: <Offers />,
        },
        // ,
        {
          path: "offers/:offerId",
          id: "offer-stepper",
          element: <ContractStepperPage />,
          children: [
            {
              path: "",
              element: <Navigate to="sellers" />,
            },
            {
              path: "sellers",
              element: <SellerDetails />,
            },
            {
              path: "price",
              element: <Price />,
            },
            {
              path: "buyers",
              element: <BuyersDetails />,
            },
            {
              path: "finance",
              element: <Finance />,
            },
            {
              path: "agent",
              element: <SellerAgent />,
            },
            {
              path: "buyers-solicitor",
              element: <BuyersSolicitor />,
            },
            {
              path: "solicitor",
              element: <SellerSolicitor />,
            },
            {
              path: "property",
              element: <PropertyStep />,
            },
            {
              path: "special-conditions",
              element: <SpecialConditions />,
            },
            {
              path: "encumbrances",
              element: <Encumbrances />,
            },
            {
              path: "pool-safety",
              element: <PoolSafety />,
            },
            {
              path: "safety-switches-and-alarms",
              element: <SafetyAlarmsAndSwitches />,
            },
            {
              path: "neighbourhood-disputes",
              element: <NeighbourhoodDisputes />,
            },
            {
              path: "annexures",
              element: <Annexures />,
            },
            {
              path: "buyer-details",
              element: <BuyersDetails />,
            },
            { path: "settlement", element: <Settlement /> },
            { path: "offer-draft-view", element: <Review /> },
            {
              path: "review",
              element: <Review />,
            },
          ],
        },
       
      ],
    },
    {
      path: "/workspaces/:workspaceId/document/:documentName",
      element: <ViewDocument />,
      id: "view-document",
    },
    {
      path: "/accept-invitation/:invitation",
      element: <AcceptInvitation />,
      id: "accept-invitation",
    },
    // {
    //   path: "/settings",
    //   element: <SettingsPage />,
    //   id: "settings",
    //   children: [
    //     {
    //       path: "my-details",
    //       element: <MyDetails />,
    //     },
    //     {
    //       path: "account",
    //       element: <Account />,
    //     },
    //   ],
    // },
    // {
    //   path: "/manage-users",
    //   element: <ManageUsers />,
    //   id: "manage-users",
    // },
  ],
  {
    future: {
      // Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
      v7_normalizeFormMethod: true,
    },
  },
)

const theme = createTheme({
  palette: {
    primary: {
      main: "#014597",
      dark: "#023676",
      light: "#94a6c9",
      contrastText: "#fafafa",
    },
    secondary: {
      main: "#f5793b",
    },

    background: {
      default: "#f9f9f9", // Set your desired color
      paper: "#fcfcfc",
    },
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
})

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Box>
            <RouterProvider router={router} />
          </Box>
        </Provider>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
