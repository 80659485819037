import { FC, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  Box,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  Participant,
  ParticipantParticipantTypeEnum,
} from "../../../landconnex-api-client";
import { OnParticipantChanged } from "../../../components/events";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import ConfirmationDialog from "../../../components/confirmation-dialog";
import ContactDetails from "../../../components/contact-details";
import ParticipantNameForm from "../../../components/participant-name-form";
import AddressForm from "../../../components/address-form";
import AutoSave from "../../../components/auto-save";

export interface ClientDetailProps {
  client: Participant;
  index: number;
  onChanged: OnParticipantChanged;
  onDelete: OnParticipantChanged;
}

const ClientDetail: FC<ClientDetailProps> = ({
  client,
  index,
  onChanged,
  onDelete,
}) => {
  const [localClient, setLocalClient] = useState(client);
  const [confirmationDialogState, setConfirmationDialogState] = useState<
    number[]
  >([]);
  const theme = useTheme();
  const formControls = useForm<Participant>({
    defaultValues: localClient,
  // resolver: yupResolver(schema),
  });
  const { handleSubmit, control, setValue, reset, trigger } = formControls;

  useEffect(() => {
    setLocalClient(client);
    reset(client);
    trigger();
  }, [client]);

  const onSubmit: SubmitHandler<Participant> = (data) => {
    onChanged(data);
  };

  return (
    <Box
      key={localClient.id}
      sx={{
        maxWidth: "1024px",
        margin: "15px auto",
        padding: { sm: "30px", xs: "30px 10px" },
        background: "#faf7f7",
        borderRadius: "30px",
        boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
      }}
    >
      <Grid item xs={12} sm={12}>
        <ConfirmationDialog
          open={confirmationDialogState.includes(localClient.id!)}
          message={`Remove ${
            client.organisationName ||
            (client.firstName && client.lastName
              ? `${client.firstName} ${client.lastName}`
              : `Client ${index + 1}`)
          } from the contract?`}
          title="Remove client?"
          onClose={(result) => {
            if (result) {
              onDelete(localClient);
            }
            setConfirmationDialogState([]);
          }}
        />
      </Grid>

      <Grid item style={{ borderColor: pink[400], borderTop: 3 }}>
        <FormProvider {...formControls}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              {/* Client header section */}
              <Grid item xs={12} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">
                    {`${
                      client.organisationName ||
                      (client.firstName && client.lastName
                        ? `${client.firstName} ${client.lastName}`
                        : `Client ${index + 1}`)
                    }`}
                  </Typography>
                  <IconButton
                    color="primary"
                    onClick={() =>
                      setConfirmationDialogState([localClient.id!])
                    }
                  >
                    <DeleteForeverOutlinedIcon fontSize="large" color="error" />
                  </IconButton>
                </Box>
                <Divider />
              </Grid>

              {/* Select client type */}
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth variant="filled">
                  <InputLabel size="small" id="clientTypeLabel">
                    Client Type
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="clientTypeLabel"
                    name="participantType"
                    label="Client Type"
                    value={localClient.participantType}
                    onChange={(event) => {
                      const updatedClient = {
                        ...localClient,
                        participantType: event.target
                          .value as ParticipantParticipantTypeEnum,
                      };
                      setLocalClient(updatedClient); // Update local client state
                      onChanged(updatedClient); // Call onChanged with updated client
                    }}
                  >
                    {Object.values(ParticipantParticipantTypeEnum).map(
                      (type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>

              {/* Participant name form */}
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Name
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ParticipantNameForm
                  control={control}
                  captureMiddleName={true}
                  captureGst={true}
                  participantType={localClient.participantType!}
                />
              </Grid>

              {/* Contact details */}
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Contact Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ContactDetails control={control} />
              </Grid>

              {/* Address form */}
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Address
                </Typography>
              </Grid>
              <AddressForm
                control={control}
                address={localClient}
                setValue={setValue}
              />
            </Grid>
            <AutoSave defaultValues={client} onSubmit={onSubmit} />
          </form>
        </FormProvider>
      </Grid>
    </Box>
  );
};

export default ClientDetail;
