import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { withAuthenticationRequired } from "react-oidc-context";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  AcceptInvitationStatus,

  selectAcceptInvitationState,
} from "./accept-invitation-slice";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Page from "../../components/page";
import { accept } from "./thunks";
import KonveiModal from "../../components/modal/konvei-modal";
import { Box } from "@mui/material";
import LCButton from "../../components/button";
import RedirectToLogin from "../../components/redirect-to-login";

const AcceptInvitation: FC = () => {
  const { invitation } = useParams();
  const navigate = useNavigate();
  const state = useAppSelector(selectAcceptInvitationState);
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(true);

  useEffect(() => {
    if (
      state.invitation !== invitation &&
      state.status === AcceptInvitationStatus.initial
    ) {
      console.log("accept invitation.");
      dispatch(accept(invitation as string));
    }
  }, [state.invitation, state.status, invitation, dispatch]);

  const renderContent = () => {
    switch (state.status) {
      case AcceptInvitationStatus.initial:
      case AcceptInvitationStatus.acceptInvitationPending:
        return <CircularProgress />;
      case AcceptInvitationStatus.acceptInvitationFulfilled:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              🎉 Success! You're In! 🎉
            </Typography>
            <Typography variant="body1" paragraph>
              You've just accepted an invitation to join the{" "}
              <strong>landconnex workspace</strong> for <em>[Property Name]</em>
              . Welcome aboard!
            </Typography>
            <Typography variant="body2" style={{ marginBottom: 30 }}>
              Ready to explore? Head over to the{" "}
              <Link onClick={() => navigate("/workspaces")} color="primary">
                workspaces
              </Link>{" "}
              to view the property and get started.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <LCButton
                label="Go to Home"
                color="#000"
                onClick={() => navigate("/")}
              />

              <LCButton
                label="Go to Workspaces"
                color="#8F1219"
                onClick={() => navigate("/workspaces")}
              />
            </Box>
          </>
        );
      case AcceptInvitationStatus.failure:
        return (
          <>
            <Typography variant="h4" gutterBottom>
              The invitation has already been accepted.
            </Typography>
            <Typography variant="body1" style={{ marginBottom: 30 }}>
              It looks like this invitation has already been accepted. If you
              believe this is an error, please contact support.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <LCButton
                label="Go to Home"
                color="#000"
                onClick={() => navigate("/")}
              />

              <LCButton
                label="Go to Workspaces"
                color="#8F1219"
                onClick={() => navigate("/workspaces")}
              />
            </Box>
          </>
        );
      default:
        return <></>;
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    navigate("/");
  };

  return (
    <Page title="Accept an invitation">
      <KonveiModal
        open={modalOpen}
        onClose={handleModalClose}
        children={renderContent()}
      />
    </Page>
  );
};

export default withAuthenticationRequired(AcceptInvitation, {
  OnRedirecting: () => <RedirectToLogin />,
});
