import Grid from "@mui/material/Grid";
import { FC, Fragment, useEffect, useRef } from "react";
import PDFViewer from "../../../components/pdf-viewer";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";
import LCCard from "../../../components/cards/lc-card";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  ContractPreviewStatus,
  contractStepperState,
} from "../contract-stepper-slice";
import { completeContractDrafting, getPdf, sendForSigning } from "../thunks";
import LCButton from "../../../components/button";
import { workspaceState } from "../../workspace/workspace-slice";
import { getParams } from "../helpers";

const Review: FC = () => {
  const state = useAppSelector(contractStepperState);
  const workspace = useAppSelector(workspaceState);
  const dispatch = useAppDispatch();
  const params = useParams();
  const workspaceId = Number(params.workspaceId);
  const workspaceParams = getParams(params);
  const navigate = useNavigate();

  const pdfViewerRef = useRef<HTMLDivElement>(null);

  const handleComplete = async () => {
    try {
      const success = await dispatch(completeContractDrafting(workspaceId));
      if (success) {
        setTimeout(() => {
          navigate(`/workspaces/${workspaceId}`);
        }, 1500);
      } else {
        console.error("Failed to complete preparation");
      }
    } catch (error) {
      console.error("Error occurred while completing preparation:", error);
    }
  };

  useEffect(() => {
    if (state.contractPreviewStatus === ContractPreviewStatus.initial) {
      dispatch(
        getPdf({
          workspaceId: workspaceParams.workspaceId!,
          offerId: workspaceParams.offerId!,
        })
      );
    }
  }, [state.status]);

  const handleContractReady = (): void => {
    dispatch(
      sendForSigning({
        workspaceId: workspaceParams.workspaceId!,
        offerId: workspaceParams.offerId!,
      })
    );
  };

  const scrollToTop = () => {
    pdfViewerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Fragment>
      {state.contractPreviewStatus === ContractPreviewStatus.loading ? (
        <Grid
          container
          spacing={2}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <CircularProgress size={100}></CircularProgress>
        </Grid>
      ) : null}
      {state.contractPreviewStatus === ContractPreviewStatus.ready ? (
        <Fragment>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
          >
            {workspace.workflow?.subState === "contractPreparationPreparing" ? (
              <Grid item xs={12} sm={6}>
                <LCCard
                  title="Complete Preparation"
                  txtColor="#000"
                  titleColor="#000"
                  bgColor="#fff"
                  sxCard={{
                    width: "100%",
                    minHeight: 200,
                    outline: "2px solid rgba(208, 226, 255, .5)",
                  }}
                >
                  <Typography mb={2}>
                    When you have completed preparing the contract and contract
                    disclosures. Notify the workspace by completing this task.
                    Offers to buyers cannot be sent until you finish this
                    activity.
                  </Typography>
                  <Box sx={{ width: "90px", margin: "0 auto" }}>
                    <LCButton
                      label="Complete"
                      onClick={handleComplete}
                      size="small"
                      color="green"
                    />
                  </Box>
                </LCCard>
              </Grid>
            ) : workspace.workflow?.subState === "acceptingOffers" ? (
              <Grid item xs={12} sm={6}>
                <LCCard
                  title="Confirm Agreement"
                  txtColor="#000"
                  titleColor="#000"
                  bgColor="#fff"
                  sxCard={{
                    width: "100%",
                    minHeight: 200,
                    outline: "2px solid rgba(208, 226, 255, .5)",
                  }}
                >
                  <Typography mb={2}>
                    Both you and the seller have reviewed and edited the
                    contract and disclosures. Please ensure all changes are
                    correct and that you agree with the final terms before
                    proceeding.
                  </Typography>

                  <Box sx={{ width: "80px", margin: "0 auto" }}>
                    <LCButton
                      label="Agree"
                      size="small"
                      color="green"
                      onClick={handleContractReady}
                    />
                  </Box>
                </LCCard>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <div ref={pdfViewerRef}>
                <PDFViewer url={state.url} />
              </div>
            </Grid>
          </Grid>

          <Box
            sx={{
              margin: "0 auto",
              width: "160px",
            }}
          >
            <LCButton
              label="Scroll to Top"
              onClick={scrollToTop}
              color="#014597"
            />
          </Box>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default Review;
