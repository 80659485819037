import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { Box, MenuItem, Select, useTheme } from "@mui/material";
import FormWrapper from "../../agent-appointment/components/form-wrapper";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import { contractStepperState } from "../contract-stepper-slice";
import { getParams } from "../helpers";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { Contract } from "../../../landconnex-api-client";
import AutoSave from "../../../components/auto-save";
import FormInputNumeric from "../../../components/form-input-numeric";
import { saveContract } from "../thunks";

const Price: React.FC = () => {
  const state = useAppSelector(contractStepperState);
  const dispatch = useAppDispatch();
  const params = useParams();

  const workspaceParams = getParams(params);
  const theme = useTheme();
  const depositInitialDueOptions = ["1 day after contract signing"];
  const depositBalanceDueOptions = [
    "1 business day from satisfaction of finance condition",
    "1 business day from satisfaction of building and pest condition",
    "1 business day from unconditional date",
  ];

  const contractFormMethods = useForm<Contract>({
    defaultValues: state.contract,
    //resolver: yupResolver(schema),
  });
  const handleOnSubmit = (data: Contract) => {
    dispatch(
      saveContract({
        workspaceId: workspaceParams.workspaceId,
        offerId: workspaceParams.offerId,
        contract: data,
      })
    );
  };
  const { handleSubmit, control } = contractFormMethods;
  return (
    <FormProvider {...contractFormMethods}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <FormWrapper title="Price">
          <Box
            sx={{
              maxWidth: "1024px",
              margin: "15px auto",
              padding: "30px",
              background: "#faf7f7",
              borderRadius: "30px",
              boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Price
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormInputNumeric
                  name="purchasePrice"
                  label="price"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  Deposits
                </Typography>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormInputNumeric
                    name="depositInitial"
                    label="Initial Deposit"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    control={control}
                    name="depositInitialDue"
                    render={({ field }) => (
                      <Select
                        value={field.value}
                        onChange={field.onChange}
                        name={field.name}
                        fullWidth
                        size="small"
                        variant="filled"
                        label="Initial deposit due"
                      >
                        {depositInitialDueOptions.map((d, index) => (
                          <MenuItem key={index} value={d}>{d}</MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormInputNumeric
                  name="depositBalance"
                  label="Balance Deposit"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="depositBalanceDue"
                  render={({ field }) => (
                    <Select
                      {...field}
                      fullWidth
                      size="small"
                      variant="filled"
                      label="Balance deposit due"
                    >
                      {depositBalanceDueOptions.map((d,index) => (
                        <MenuItem key={index} value={d}>{d}</MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <AutoSave
            onSubmit={handleOnSubmit}
            defaultValues={state.contract}
          ></AutoSave>
        </FormWrapper>
      </form>
    </FormProvider>
  );
};

export default Price;
