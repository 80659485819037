import { FC } from "react";
import { Property } from "../landconnex-api-client";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

export interface AddressLabelProps {
  property: Property;
}
const AddressLabel: FC<AddressLabelProps> = ({ property }) => {
  const theme = useTheme();
  return (
    <Box>
      <div
        style={{
          fontSize: 20,
          fontFamily: theme.typography.fontFamily,
          fontStyle: "normal",
          fontWeight: 500,
          color: "#8F1219",
        }}
      >
        {property.streetAddress1}
      </div>
      <div
        style={{
          fontSize: 18,
          fontFamily: theme.typography.fontFamily,
          fontStyle: "normal",
          fontWeight: 400,
          color: theme.palette.primary.main,
        }}
      >
        {property.streetAddress2}
      </div>
      <div
        style={{
          fontSize: 16,
          fontFamily: theme.typography.fontFamily,
          fontStyle: "normal",
          fontWeight: 400,
        }}
      >
        {property.locality}, {property.stateOrTerritory}, {property.postCode}
      </div>
      <div
        style={{
          fontSize: 16,
          fontFamily: theme.typography.fontFamily,
          fontStyle: "normal",
          fontWeight: 300,
        }}
      >
        Lot: {property.lot} on plan: {property.plan}
      </div>
      <div
        style={{
          fontSize: 16,
          fontFamily: theme.typography.fontFamily,
          fontStyle: "normal",
          fontWeight: 300,
        }}
      >
        Title Reference: {property.titleReference}
      </div>
    </Box>
  );
};

export default AddressLabel;
