import { createAppSlice } from "../../../app/createAppSlice"
import {
  OfferDetail,
  Workflow,
  WorkspaceSummaryNew,
} from "../../../landconnex-api-client"
import {  workspacesApi } from "../../../api"

export enum WorkspacesStatus {
  initial,
  loading,
  ready,
  error,
}
export interface IndexedWorkflowState extends Workflow {
  workspaceId: number
}
interface WorkspacesState {
  status: WorkspacesStatus
  workspaces: Array<WorkspaceSummaryNew>
  workflowStates: Array<IndexedWorkflowState>
  offers: OfferDetail[]
}

const initialState: WorkspacesState = {
  status: WorkspacesStatus.initial,
  workspaces: [],
  workflowStates: [],
  offers: [],
}

export interface GetWorkflowStateArgs {
  workspaceId: number
}

export interface GetWorkflowStateResponse {
  workspaceId: number
  workflow: Workflow
}
// If you are not using async thunks you can use the standalone `createSlice`.
export const workspacesSlice = createAppSlice({
  name: "workspaces",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: create => ({
    // The function below is called a thunk and allows us to perform async logic. It
    // can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
    // will call the thunk with the `dispatch` function as the first argument. Async
    // code can then be executed and other actions can be dispatched. Thunks are
    // typically used to make async requests.
    getWorkspaces: create.asyncThunk(
      async () => {
        const result = workspacesApi.getWorkspaces2()
        return (await result).data
      },
      {
        pending: state => {
          state.status = WorkspacesStatus.loading
        },
        fulfilled: (state, action) => {
          state.status = WorkspacesStatus.ready
          state.workspaces = action.payload.items!
        },
        rejected: state => {
          state.status = WorkspacesStatus.error
        },
      },
    ),
    getWorkflowState: create.asyncThunk<
      GetWorkflowStateResponse,
      GetWorkflowStateArgs
    >(
      async ({ workspaceId }) => {
        const result = await workspacesApi.getWorkflow(workspaceId)
        return { workspaceId: workspaceId, workflow: result.data }
      },
      {
        pending: state => {
          state.status == WorkspacesStatus.loading
        },
        fulfilled: (state, action) => {
          const index = state.workflowStates.findIndex(
            w => w.workspaceId == action.payload.workspaceId,
          )
          if (index == -1) {
            const item: IndexedWorkflowState = {
              ...action.payload.workflow,
              workspaceId: action.payload.workspaceId,
            }
            state.workflowStates.push(item)
          }
        },
      },
    ),
  }),
  // You can define your selectors here. These selectors receive the slice
  // state as their first argument.
  selectors: {
    selectWorkspaces: workspaces => workspaces.workspaces,
    selectOffers: workspaces => workspaces.offers,
    selectStatus: workspaces => workspaces.status,
    selectWorkspacesState: workspaces => workspaces,
  },
})

// Action creators are generated for each case reducer function.
export const { getWorkspaces, getWorkflowState } = workspacesSlice.actions

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const {
  selectWorkspaces,
  selectOffers,
  selectStatus,
  selectWorkspacesState,
} = workspacesSlice.selectors

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState())

//     if (currentValue % 2 === 1 || currentValue % 2 === -1) {
//       dispatch(incrementByAmount(amount))
//     }
//   }
