import { FC, Fragment, useState } from "react";
import { OfferDetail, Workflow,  Workspace } from "../../landconnex-api-client";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

import {
  participantName,
  australianCurrencyFormatter,
  currencyFromBaseUnit,
  formatDateAndTime,
  getParticipantsByRole,
} from "../../helpers/helpers";

import {
  sendForSigning,
  shareWithSeller,
} from "../../features/workspace/thunks";

import {
  cancelOffer,
  getOffer,
  inviteBuyers,
} from "../../features/contract-stepper/thunks";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { sessionState } from "../../features/session/session-slice";

import LCCard from "../cards/lc-card";
import LCButton from "../button";
import OfferData from "./offer-data";
import ParticipantList from "../../features/workspace/components/participants-list";

export interface OfferTableProps {
  offer: OfferDetail;
  workspace: Workspace;
  workflow: Workflow;
  txtColor?: string;
  bgColor?: string;
  boxShadow?: boolean;
}

const OfferCard: FC<OfferTableProps> = ({
  offer,
  workspace,
  txtColor = "#000",
  bgColor = "#f5f9fa",
  boxShadow = false,
  workflow,
}) => {
  let buyers = getParticipantsByRole(offer.participants, "buyer");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sessState = useAppSelector(sessionState);
  const workflowOffer = workflow.offers.find(
    (wOffer) => wOffer.offerId === offer.offer.id
  );

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleInviteBuyers(offerId: number): void {
    dispatch(
      inviteBuyers({
        workspaceId: workspace.id,
        offerId: offerId,
      })
    );
  }

  function handleSendToSellers(offerId: number): void {
    dispatch(
      sendForSigning({
        workspaceId: workspace.id,
        offerId: offerId,
      })
    );
  }

  function handleShareWithSeller(offerId: number): void {
    dispatch(
      shareWithSeller({
        workspaceId: workspace.id,
        offerId: offerId,
      })
    );
  }

  function handleCancelOffer(offerId: number): void {
    dispatch(
      cancelOffer({
        workspaceId: workspace.id,
        offerId: offerId,
      })
    );
  }

  // const handleSendForSigning = (offerId: number) => {
  //   dispatch(
  //     sendForSigning({
  //       workspaceId: workspace.id,
  //       offerId: offerId,
  //     })
  //   );
  // };

  const handleMenuItemClick = (action: any, offerId: number) => {
    if (action === "Edit") {
      dispatch(getOffer({ workspaceId: workspace.id, offerId: offerId! }));

      navigate(`${offerId}`);
    } else if (action === "Draft") {
      navigate(`${offerId}/draft`);
    } else if (action === "inviteBuyers") {
      handleInviteBuyers(offerId);
    } else if (action === "sendToSeller") {
      handleSendToSellers(offerId);
    } else if (action === "shareWithSeller") {
      handleShareWithSeller(offerId);
    } else if (action === "cancelOffer") {
      handleCancelOffer(offerId);
    }
    handleClose();
  };

  const stateStyles: Record<
    string,
    {
      color: string;
      statusBgColor: string;
      message: string;
      icon: React.ReactNode;
    }
  > = {
    OfferCreated: {
      color: "rgb(1, 67, 97)",
      statusBgColor: "rgb(229, 246, 253)",
      icon: (
        <CheckCircleOutlinedIcon sx={{ color: "#0288d1", width: "20px" }} />
      ),
      message: "Offer Created",
    },
    OfferBuyerSigningSent: {
      color: "rgb(102, 60, 0)",
      statusBgColor: "rgb(255, 244, 229)",
      icon: (
        <CircularProgress size={15} sx={{ color: "#ed6c02", width: "15px" }} />
      ),
      message: "Sent to Buyer for Signing",
    },
    OfferBuyerSigningCompleted: {
      color: "rgb(30, 70, 32)",
      statusBgColor: "rgb(237, 247, 237)",
      icon: (
        <CheckCircleOutlinedIcon sx={{ color: "#2e7d32", width: "20px" }} />
      ),
      message: "Buyer Signing · Completed",
    },
    OfferBuyerSigningDeclined: {
      color: "rgb(95, 33, 32)",
      statusBgColor: "rgb(253, 237, 237)",
      icon: <CancelOutlinedIcon sx={{ color: "#d32f2f", width: "20px" }} />,
      message: "Buyer Signing · Declined",
    },
    OfferSellerSigningSent: {
      color: "rgb(102, 60, 0)",
      statusBgColor: "rgb(255, 244, 229)",
      icon: (
        <CircularProgress size={15} sx={{ color: "#ed6c02", width: "15px" }} />
      ),
      message: "Sent to Seller for Signing",
    },
    OfferSellerSigningCompleted: {
      color: "rgb(30, 70, 32)",
      statusBgColor: "rgb(237, 247, 237)",
      icon: (
        <CheckCircleOutlinedIcon sx={{ color: "#2e7d32", width: "20px" }} />
      ),
      message: "Seller Signing · Completed",
    },
    OfferSellerSigningDeclined: {
      color: "rgb(95, 33, 32)",
      statusBgColor: "rgb(253, 237, 237)",
      icon: <CancelOutlinedIcon sx={{ color: "#d32f2f", width: "20px" }} />,
      message: "Seller Signing · Declined",
    },
    OfferCompletedSuccess: {
      color: "rgb(30, 70, 32)",
      statusBgColor: "rgb(237, 247, 237)",
      icon: (
        <CheckCircleOutlinedIcon sx={{ color: "#2e7d32", width: "20px" }} />
      ),
      message: "Offer Completed",
    },
    OfferCompletedDeclined: {
      color: "rgb(95, 33, 32)",
      statusBgColor: "rgb(253, 237, 237)",
      icon: <CancelOutlinedIcon sx={{ color: "#d32f2f", width: "20px" }} />,
      message: "Offer Declined",
    },
  };

  const OfferStatusDisplay = ({ state }: { state: string | undefined }) => {
    const status = stateStyles[state || ""] || { color: "#000", icon: null };

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          background: status.statusBgColor,
          padding: "10px 16px",
        }}
      >
        <Typography
          component="span"
          variant="subtitle1"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
            color: status.color,
            fontSize: "14px",
          }}
        >
          <Typography
            component="span"
            variant="subtitle1"
            sx={{
              color: "#000",
              fontSize: "14px",
            }}
          >
            Offer Status:
          </Typography>{" "}
          {status.icon} {status.message || "Unknown"}
        </Typography>
      </Box>
    );
  };

  return (
    <LCCard
      sxCardContent={{ padding: 0 }}
      sxCard={{
        width: "100%",
        minHeight: 200,
        outline: "2px solid rgba(208, 226, 255, .5)",
      }}
      title={
        <>
          {buyers.map((p, i) => {
            if (i == buyers.length - 1) {
              return participantName(p);
            } else {
              return participantName(p) + ", ";
            }
          })}
        </>
      }
      buttons={
        workspace.role == "sellerAgent" && (
          <Button color="inherit">
            <MoreVertIcon
              onClick={(e) => {
                handleClick(e);
              }}
              sx={{ zIndex: 1 }}
            />
          </Button>
        )
      }
      txtColor={txtColor}
      titleColor="#000"
      bgColor={bgColor}
      boxShadow={boxShadow}
    >
      {workspace.role == "sellerAgent" && (
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {/* <MenuItem
            onClick={() => handleMenuItemClick("Edit", offer.offer.id!)}
          >
            Edit
          </MenuItem> */}
          {/* <MenuItem
            onClick={() => handleMenuItemClick("Draft", offer.offer.id!)}
          >
            Draft
          </MenuItem> */}
          <MenuItem
            onClick={() => handleMenuItemClick("inviteBuyers", offer.offer.id!)}
          >
            Invite Buyers
          </MenuItem>

          <MenuItem
            onClick={() =>
              handleMenuItemClick("shareWithSeller", offer.offer.id!)
            }
          >
            Share to Seller
          </MenuItem>

          {/* <MenuItem
            onClick={
              () => handleSendForSigning(offer.offer.id!)
              // handleMenuItemClick("sendForSigning", offer.offer.id!)
            }
          >
            Send contract for signing
          </MenuItem> */}
          <MenuItem
            onClick={() => handleMenuItemClick("cancelOffer", offer.offer.id!)}
          >
            Cancel Offer
          </MenuItem>
        </Menu>
      )}

      <Divider />
      <OfferStatusDisplay state={workflowOffer?.state} />

      <Box sx={{ padding: "16px", margin: "15px auto " }}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 1 }}
          >
            <OfferData
              icon={<MonetizationOnOutlinedIcon />}
              value={australianCurrencyFormatter.format(
                currencyFromBaseUnit(offer.offer.purchasePrice)!
              )}
            />

            <OfferData
              label="Deposit: "
              value={
                offer.offer.depositInitial == null
                  ? "$0.00"
                  : australianCurrencyFormatter.format(
                      currencyFromBaseUnit(offer.offer.depositInitial)!
                    )
              }
            />
            <OfferData
              isCheck
              label="Finance"
              value={offer.offer.financeDate}
            />
            {workspace.role === "sellerAgent" && (
              <OfferData
                isCheck
                label="Shared with Seller"
                value={workflowOffer?.sharedWithSeller || false}
              />
            )}
            {workspace.role !== "seller" &&
              workspace.role !== "sellerSolicitor" && (
                <Fragment>
                  <OfferData
                    isCheck
                    label="Buyer invited"
                    value={
                      workflow.offers.find(
                        (wOffer) => wOffer.offerId === offer.offer.id
                      )?.buyerInvited || false
                    }
                  />
                </Fragment>
              )}
          </Box>

          <Box
            sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 1 }}
          >
            <OfferData
              icon={<CalendarMonthOutlinedIcon />}
              value={formatDateAndTime(offer.offer.settlementDate, false)}
            />
            <OfferData
              isCheck
              label="Building & Pest"
              value={offer.offer.buildingAndPestInspection}
            />
            <OfferData
              isCheck
              label="Special Conditions"
              value={offer.offer.specialConditions}
              hideValue
            />
            {workspace.role !== "seller" &&
              workspace.role !== "sellerSolicitor" && (
                <OfferData
                  isCheck
                  label="Buyer Solicitor invited"
                  value={
                    workflow.offers.find(
                      (wOffer) => wOffer.offerId === offer.offer.id
                    )?.buyerSolicitorInvited || false
                  }
                />
              )}
          </Box>
        </Box>
        <ParticipantList
          participants={offer.participants!}
          currentUserEmail={sessState.details?.email!}
          currentUserRole={workspace.role}
          simplifiedLayout={true}
          roles={[
            "sellerAgent",
            "seller",
            "sellerSolicitor",
            "buyer",
            "buyerSolicitor",
          ]}
        />
      </Box>

      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          marginTop: 3,
        }}
      >
        {["OfferCreated", "OfferBuyerSigningCompleted"].includes(
          workflowOffer?.state!
        ) &&
          (workspace.role === "sellerAgent" ||
            workspace.role === "buyer" ||
            workspace.role === "seller") && (
            <Box sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  fontSize: "16px",
                  fontWeight: "semibold",
                }}
              >
                {workspace.role === "sellerAgent"
                  ? `Send contract to ${
                      workflowOffer?.state === "OfferCreated"
                        ? "Buyer"
                        : "Seller"
                    } for signing`
                  : "Sign Contract"}
              </Box>
            </Box>
          )}
        <Box sx={{ display: "flex", justifyContent: "center", gap: 3 }}>
          <Box width={100}>
            <LCButton
              label="Review"
              color="#8F1219"
              size="small"
              onClick={() => handleMenuItemClick("Edit", offer.offer.id!)}
            />
          </Box>
          {["OfferCreated", "OfferBuyerSigningCompleted"].includes(
            workflowOffer?.state!
          ) &&
            (workspace.role === "sellerAgent" ||
              workspace.role === "buyer" ||
              workspace.role === "seller") && (
              <Box width={100}>
                <LCButton
                  label={workspace.role === "sellerAgent" ? "Send" : "Sign"}
                  color="#2e7d32"
                  size="small"
                  onClick={() =>
                    dispatch(
                      sendForSigning({
                        workspaceId: workspace.id,
                        offerId: offer.offer.id!,
                      })
                    )
                  }
                />
              </Box>
            )}
        </Box>
      </Box>
      {/* <pre>{JSON.stringify(offerState,null,2)}</pre>
      <pre>{JSON.stringify(workspace,null,2)}</pre> */}
    </LCCard>
  );
};

export default OfferCard;
