import {
  Check,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import {
  Box,
  MobileStepper,
  Step,
  StepButton,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  styled,
  useTheme,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import LCButton from "./button";

export type OnStepChanged = (step: string) => void;
export interface KonveiStepperProps {
  steps: Array<string>;
  onStepChanged: OnStepChanged;
  activeStep: string;
  isCompressed?: boolean;
}

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#6d6d6d",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#6d6d6d",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    // borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 1,
    borderRadius: 3,
  },
}));

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#8F1219",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "green",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const KonveiStepper: React.FC<KonveiStepperProps> = ({
  steps,
  onStepChanged,
  activeStep,
  isCompressed,
}) => {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("lg"));

  const handleNext = () => {
    const index = steps.indexOf(activeStep);
    if (index < steps.length - 1) {
      onStepChanged(steps[index + 1]);
    }
  };

  const handleBack = () => {
    const index = steps.indexOf(activeStep);

    if (index > 0) {
      onStepChanged(steps[index - 1]);
    }
  };
  const index = steps.indexOf(activeStep);

  return (
    <Box>
      {isMdDown || isCompressed ? (
        <MobileStepper
          variant="text"
          steps={steps.length}
          position="static"
          activeStep={index}
          sx={{ background: "#fff" }}
          nextButton={
            <Box sx={{ width: 80, zIndex: 1 }}>
              <LCButton
                label="Next"
                color="#000"
                size="small"
                onClick={handleNext}
                disabled={index === steps.length - 1}
                endIcon={<KeyboardArrowRight />}
              />
            </Box>
          }
          backButton={
            <Box sx={{ width: 80, zIndex: 1 }}>
              <LCButton
                label="Back"
                color="#000"
                size="small"
                onClick={handleBack}
                //  disabled={activeStep === 0}
                startIcon={<KeyboardArrowLeft />}
              />
            </Box>
          }
        />
      ) : (
        <>
          <Stepper
            style={{ marginTop: 30 }}
            activeStep={index}
            alternativeLabel
            connector={<QontoConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  color="inherit"
                  onClick={() => {
                    onStepChanged(steps[index]);
                  }}
                >
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {label}
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: 5,
            }}
          >
            <Box sx={{ width: "120px" }}>
              <LCButton
                label="Back"
                color="#000"
                disabled={index === 0}
                onClick={handleBack}
                startIcon={<KeyboardArrowLeft />}
              />
            </Box>
            <Box sx={{ width: "120px" }}>
              <LCButton
                label="Next"
                color="#000"
                onClick={handleNext}
                disabled={index === steps.length - 1}
                endIcon={<KeyboardArrowRight />}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default KonveiStepper;
