import type { Action, ThunkAction } from "@reduxjs/toolkit"
import { combineSlices, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"

import { workspacesSlice } from "../features/state/workspaces/workspacesSlice"
import { workspaceSlice } from "../features/workspace/workspace-slice"
import { contractStepperSlice } from "../features/contract-stepper/contract-stepper-slice"
import { createWorkspaceSlice } from "../features/create-workspace/create-workspace-slice"
import { agentAppointmentSlice } from "../features/agent-appointment/agent-appointment-slice"
import { offersSlice } from "../features/offers/offers-slice"
import { sessionSlice } from "../features/session/session-slice"
import { authCallbackSlice } from "../features/auth-callback/auth-callback-slice"
import { offerDraftSlice } from "../features/offer-draft/offer-drafts-slice"
import { viewInvoiceSlice } from "../features/view-invoice/view-invoice-slice"
import { acceptInvitationSlice } from "../features/accept-invitation/accept-invitation-slice"
import { viewDocumentSlice } from "../features/view-document/view-document-slice"
import { createSubscriptionSlice } from "../features/create-subscription/create-subscription-slice"
import { workspaceEventsSlice } from "../features/events/eventsSlice"
import { manageUsersSlice } from "../features/manage-users/manage-users-slice"
import { settingsSlice } from "../features/settings/settings-slice"
import { contractSlice } from "../features/contract/contract-slice"
import { contractsSlice } from "../features/contracts/contracts-slice"

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
const rootReducer = combineSlices(
  acceptInvitationSlice,
  agentAppointmentSlice,
  authCallbackSlice,
  contractSlice,
  contractsSlice,
  contractStepperSlice,
  createWorkspaceSlice,
  createSubscriptionSlice,
  manageUsersSlice,
  offerDraftSlice,
  offersSlice,
  sessionSlice,
  settingsSlice,
  viewDocumentSlice,
  viewInvoiceSlice,
  workspaceEventsSlice,
  workspacesSlice,
  workspaceSlice,
)
// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = (preloadedState?: Partial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    // middleware: getDefaultMiddleware => {
    //   return getDefaultMiddleware().concat(quotesApiSlice.middleware)
    // },
    preloadedState,
  })
  // configure listeners using the provided defaults
  // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
  setupListeners(store.dispatch)
  return store
}

export const store = makeStore()

// Infer the type of `store`
export type AppStore = typeof store
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore["dispatch"]
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>
