import { Typography, Grid } from "@mui/material"
import { FC } from "react"
import TimelineStage from "./timeline-stage"
import {
  AgentAppointmentState,
  ContractPreparationState,
  OfferNegotiationState,
  SettlementState,
  Workflow,
} from "../../landconnex-api-client"

export interface TimelineProps {
  workflow: Workflow
}

const agentAppointmentLabel = (state: AgentAppointmentState): string => {
  switch (state) {
    case "agentAppointmentPreparing":
      return "Preparing"
    case "agentAppointmentAwaitingSigning":
      return "Awaiting Signing"
    case "agentAppointmentAwaitingSellerAdmission":
      return "Awaiting Seller Admission"
    case "agentAppointmentComplete":
      return "Completed"
    default:
      return "Completed"
  }
}

const agentAppointmentCurrentStepNumber = (
  state: AgentAppointmentState,
): number => {
  switch (state) {
    case "agentAppointmentPreparing":
      return 1
    case "agentAppointmentAwaitingSigning":
      return 2
    case "agentAppointmentAwaitingSellerAdmission":
      return 3
    case "agentAppointmentComplete":
      return 4
    default:
      return 4
  }
}

const contractPreparationLabel = (state: ContractPreparationState): string => {
  switch (state) {
    case "contractPreparationNotStarted":
      return "Not Started"
    case "contractPreparationAwaitingSellerSolicitorSelection":
      return "Awaiting Solicitor Selection"
    case "contractPreparationAwaitingSellerSolicitorAdmission":
      return "Awaiting Solicitor Admission"
    case "contractPreparationPreparing":
      return "Preparing"
    case "contractPreparationComplete":
      return "Completed"
    default:
      return "Completed"
  }
}

const contractPreparationStepNumber = (
  state: ContractPreparationState,
): number => {
  switch (state) {
    case "contractPreparationNotStarted":
      return 0
    case "contractPreparationAwaitingSellerSolicitorSelection":
      return 1
    case "contractPreparationAwaitingSellerSolicitorAdmission":
      return 2
    case "contractPreparationPreparing":
      return 3
    case "contractPreparationComplete":
      return 4
    default:
      return 4
  }
}

const offerNegotiationLabel = (state: OfferNegotiationState): string => {
  switch (state) {
    case "offerNegotiationNotStarted":
      return "Not Started"
    case "offerNegotiationAcceptingOffers":
      return "Accepting Offers"
    case "offerNegotiationComplete":
      return "Completed"

    default:
      return "Completed"
  }
}

const offerNegotiationStepNumber = (state: OfferNegotiationState): number => {
  switch (state) {
    case "offerNegotiationNotStarted":
      return 0
    case "offerNegotiationAcceptingOffers":
      return 1
    case "offerNegotiationComplete":
      return 2
    default:
      return 2
  }
}

const settlementLabel = (state: SettlementState): string => {
  switch (state) {
    case "settlementNotStarted":
      return "Not Started"
    default:
      return "Not Started"
  }
}

const settlementStepNumber = (state: SettlementState): number => {
  switch (state) {
    case "settlementNotStarted":
      return 0
    default:
      return 0
  }
}

const Timeline: FC<TimelineProps> = ({ workflow }) => {
  return (
    <Grid
      container
      sx={{
        flexDirection: {
          xs: "column",
          sm: "row",
        },
      }}
    >
      <Grid key={"agentAppointment"} item xs={12} sm={6} md={3}>
        <TimelineStage
          primaryColor={"green"}
          steps={4}
          currentStep={agentAppointmentCurrentStepNumber(
            workflow.agentAppointment,
          )}
          label={
            <>
              <Typography
                variant="body2"
                component="span"
                sx={{ display: "block", fontWeight: 500 }}
              >
                Agent Appointment
              </Typography>
              <Typography
                variant="caption"
                component="span"
                sx={{ display: "block" }}
              >
                {agentAppointmentLabel(workflow.agentAppointment)}
              </Typography>
            </>
          }
        />
      </Grid>
      <Grid key={"contractPreparation"} item xs={12} sm={6} md={3}>
        <TimelineStage
          primaryColor={"green"}
          steps={4}
          currentStep={contractPreparationStepNumber(
            workflow.contractPreparation,
          )}
          label={
            <>
              <Typography
                variant="body2"
                component="span"
                sx={{ display: "block", fontWeight: 500 }}
              >
                Contract Preparation
              </Typography>
              <Typography
                variant="caption"
                component="span"
                sx={{ display: "block" }}
              >
                {contractPreparationLabel(workflow.contractPreparation)}
              </Typography>
            </>
          }
        />
      </Grid>
      <Grid key={"offerNegotiation"} item xs={12} sm={6} md={3}>
        <TimelineStage
          primaryColor={"green"}
          steps={4}
          currentStep={offerNegotiationStepNumber(workflow.offerNegotiation)}
          label={
            <>
              <Typography
                variant="body2"
                component="span"
                sx={{ display: "block", fontWeight: 500 }}
              >
                Offer Negotiation
              </Typography>
              <Typography
                variant="caption"
                component="span"
                sx={{ display: "block" }}
              >
                {offerNegotiationLabel(workflow.offerNegotiation)}
              </Typography>
            </>
          }
        />
      </Grid>
      <Grid key={"settlement"} item xs={12} sm={6} md={3}>
        <TimelineStage
          primaryColor={"green"}
          steps={4}
          currentStep={settlementStepNumber(workflow.settlement)}
          label={
            <>
              <Typography
                variant="body2"
                component="span"
                sx={{ display: "block", fontWeight: 500 }}
              >
                Settlement
              </Typography>
              <Typography
                variant="caption"
                component="span"
                sx={{ display: "block" }}
              >
                {settlementLabel(workflow.settlement)}
              </Typography>
            </>
          }
        />
      </Grid>
    </Grid>
  )
}

export default Timeline
