import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { FC } from "react";
import {
  Participant,
  Workflow,
  WorkspaceRoleEnum,
  WorkspaceSummary,
} from "../../../landconnex-api-client";
import { participantName } from "../../../helpers/helpers";
import dayjs from "dayjs";

export interface AgentHeaderProps {
  workspaceSummary: WorkspaceSummary;
  workflow: Workflow;
  participants: Participant[];
}

const AgentHeader: FC<AgentHeaderProps> = ({
  workspaceSummary,
  participants,
}) => {
  const sellers = participants.filter(
    (x) => x.role == WorkspaceRoleEnum.Seller
  );

  return (
    <Card
      sx={{
        boxShadow: "0 8px 24px 0 rgba(0, 0, 0, 0.1)",
        borderRadius: "12px",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        background: "none",
        backgroundImage: "none",
        border: "none",
        backgroundColor: "#c0e9ff",
        color: "#5a5a5a",
        overflow: "hidden",
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          width: "210px",
          height: "210px",
          background: "#5cc6ff",
          borderRadius: "50%",
          opacity: 0.5,
          top: "-125px",
          right: "-15px",
        },
        "&:after": {
          content: "''",
          position: "absolute",
          width: "210px",
          height: "210px",
          background: "#3f85ab",
          borderRadius: "50%",
          top: "-85px",
          right: "-95px",
        },
      }}
    >
      <CardHeader
        title={
          <Typography variant="h5" component="div">
            {workspaceSummary.property?.streetAddress1}
          </Typography>
        }
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">Workspace Status</Typography>
          </Grid>
        </Grid>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Workspace ID: {workspaceSummary.workspace?.id}
        </Typography>
        <Typography variant="body2" sx={{ marginTop: 1 }}>
          Created Date: {dayjs().format("LL")}
        </Typography>
        <Typography variant="body2">
          Seller: {sellers.map((s) => participantName(s)).join()}
        </Typography>
        <Typography variant="body2">
          Role: {workspaceSummary.workspace?.role}
        </Typography>
      </CardContent>
    </Card>
    // <Paper >
    //   <Grid container spacing={1} sx={{ padding: 1 }}>
    //     <Grid item xs={6}>

    //       <Typography variant="h6">
    //         {workspaceSummary!.property?.streetAddress1}
    //       </Typography>
    //     </Grid>
    //     <Grid item xs={6}>
    //       {workspaceSummary.workspace?.role}
    //     </Grid>
    //     <Grid item xs={12} md={4}>
    //       Workspace ID: {workspaceSummary.workspace!.id}
    //     </Grid>
    //     <Grid item xs={12} md={4}>
    //       State: todo
    //     </Grid>
    //     <Grid item xs={12} md={4}></Grid>
    //     <Grid item xs={12} md={4}>
    //       Seller: {sellers.map((s) => participantName(s)).join()}
    //     </Grid>
    //     <Grid item xs={12} md={4}>
    //       Last Updated: {dayjs().format("LLLL")}
    //     </Grid>
    //     <Grid item xs={12} md={4}></Grid>
    //     <Grid item xs={12} md={4}>
    //       Appointment Date: {dayjs().format("LL")}
    //     </Grid>
    //     <Grid item xs={12} md={4}>
    //       Appointment Expiry Date: {dayjs().format("LL")}
    //     </Grid>
    //   </Grid>
    // </Paper>
  );
};

export default AgentHeader;
