import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDebounceValue } from "usehooks-ts";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createWorkspaceState,
  CreateWorkspaceStatus,
  selectedAddress,
} from "./create-workspace-slice";
import { FlattenedAddress } from "../../landconnex-api-client";
import { getAddress, lookupAddress, searchForTitleByAddress } from "./thunks";

const SearchForProperty: React.FC = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(createWorkspaceState);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedValue, setSelectedValue] = useState<FlattenedAddress | null>(
    null
  );
  const [debouncedAddress] = useDebounceValue(searchTerm, 1000);

  useEffect(() => {
    if (debouncedAddress) {
      dispatch(lookupAddress(debouncedAddress));
    }
  }, [debouncedAddress, dispatch]);

  useEffect(() => {
    if (selectedValue) {
      dispatch(searchForTitleByAddress({ streetAddress: selectedValue.sla }));
    }
  }, [selectedValue, dispatch]);

  const handleAddressChange = (
    _: unknown,
    newValue: FlattenedAddress | null
  ) => {
    if (newValue) {
      dispatch(getAddress(newValue.pid!));
      dispatch(searchForTitleByAddress({ streetAddress: newValue.sla }));
      dispatch(selectedAddress(newValue));
      setSelectedValue(newValue);
    }
  };

  return (
    <>
      <Typography variant="h5" my={2}>
        Property
      </Typography>
      <Typography variant="subtitle1" my={2}>
        Enter the address of the property, then select the address from the
        dropdown.
      </Typography>
      <Box sx={{ display: "flex", margin: "15px 0", alignItems: "center" }}>
        <Autocomplete
          fullWidth
          autoComplete
          value={selectedValue}
          onChange={handleAddressChange}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => option.pid === value.pid}
          options={state.matches}
          onInputChange={(_, value) => setSearchTerm(value)}
          getOptionLabel={(option) => option.sla || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Address"
              variant="filled"
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {state.status === CreateWorkspaceStatus.pending && (
                      <CircularProgress color="inherit" size={24} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
      {state.status === CreateWorkspaceStatus.pending && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CircularProgress />
          <Typography ml={2}>
            Searching title registry. This can take up to 30 seconds...
          </Typography>
        </Box>
      )}
      {state.addressSearchResult?.length > 0 && (
        <>
          <Typography variant="h6" mt={2}>
            Selected Property
          </Typography>
          <List>
            {state.addressSearchResult.map((address, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={address.address}
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="subtitle2"
                        display="block"
                      >
                        Title: {address.reference}
                      </Typography>
                      <Typography
                        component="span"
                        variant="subtitle2"
                        display="block"
                      >
                        Lot on Plan: {address.lotOnPlan}
                      </Typography>
                      <Typography
                        component="span"
                        variant="subtitle2"
                        display="block"
                      >
                        Owner name: {address.ownerName}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </>
  );
};

export default SearchForProperty;
