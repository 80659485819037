import { createAsyncThunk } from "@reduxjs/toolkit";

import { User } from "oidc-client-ts";
import axios from "axios";
import { appConfig } from "../../constants";

export interface GetDocumentArgs{
    workspaceId: number;
    documentName: string;
  }
  export const getDocument = createAsyncThunk<string, GetDocumentArgs>(
    "workspace/getDocument",
    async ({workspaceId, documentName}) => {
      const oidcStorage = sessionStorage.getItem(
        `oidc.user:${appConfig.authority}:${appConfig.clientId}`
      );
      const user = User.fromStorageString(oidcStorage!);
      
      const response = await axios.get(
        `${appConfig.workspacesUri}/workspaces/${workspaceId}/documents/${documentName}`,
        {
          headers: { Authorization: `Bearer ${user.access_token}` },
          responseType: "blob",
        }
      );
  
      const url = window.URL.createObjectURL(response.data!);
  
      return url;
    }
  );