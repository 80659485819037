import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardContent from "@mui/material/CardContent"
import { FC } from "react"
import { Box } from "@mui/material"

interface LCCardProps {
  title?: React.ReactNode | string
  titleExtra?: React.ReactNode
  children?: React.ReactNode
  buttons?: React.ReactNode
  titleColor?: string
  wrapperPadding?: string
  txtColor?: string
  bgColor?: string
  boxShadow?: boolean
  circleElementBefore?: string
  circleElementAfter?: string
  sxCard?: object
  sxCardHeader?: object
  sxCardContent?: object
}

const LCCard: FC<LCCardProps> = ({
  title,
  titleExtra,
  buttons,
  children,
  titleColor,
  txtColor,
  bgColor,
  boxShadow = true,
  circleElementBefore,
  circleElementAfter,
  sxCard = {},
  sxCardHeader = {},
  sxCardContent = {},
}) => {
  return (
    <Card
      sx={{
        boxShadow: boxShadow ? "0 8px 24px 0 rgba(0, 0, 0, 0.1)" : "none",
        borderRadius: "12px",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        background: "none",
        backgroundImage: "none",
        border: "none",
        backgroundColor: bgColor,
        color: "rgb(255, 255, 255)",
        overflow: "hidden",
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          width: "210px",
          height: "210px",
          background: circleElementBefore,
          borderRadius: "50%",
          opacity: 0.5,
          top: "-125px",
          right: "-15px",
          zIndex: 0,
        },
        "&:after": {
          content: "''",
          position: "absolute",
          width: "210px",
          height: "210px",
          background: circleElementAfter,
          borderRadius: "50%",
          top: "-85px",
          right: "-95px",
          zIndex: 0,
        },
        ...sxCard,
      }}
    >
      <CardHeader
        sx={{ color: titleColor, ...sxCardHeader }}
        title={
          <Box
            sx={{
              display: "flex",

              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <Box sx={{ flexShrink: 0 }}>{title}</Box>
            {titleExtra && <Box sx={{}}>{titleExtra}</Box>}
          </Box>
        }
        action={buttons}
      />
      <CardContent
        sx={{ color: txtColor, paddingBottom: "0px", ...sxCardContent }}
      >
        {children}
      </CardContent>
    </Card>
  )
}
export default LCCard
