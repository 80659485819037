import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit"
import {
  getAddress,
  lookupAddress,
  getTenancyUsers,
  createWorkspace,
  searchForTitleByAddress,
} from "./thunks"
import {
  FlattenedAddress,
  PhysicalAustralianAddress,
  TeamParticipant,
  TitleSearchResult,
  User,
} from "../../landconnex-api-client"
import { createAppSlice } from "../../app/createAppSlice"

export enum CreateWorkspaceStatus {
  initial,
  ready,
  pending,
  creatingWorkspace,
  worspaceCreated,
}
interface MattersState {
  status: CreateWorkspaceStatus
  address: string
  matches: Array<FlattenedAddress>
  selectedAddress?: FlattenedAddress
  selectedTitle?: TitleSearchResult
  users: Array<TeamParticipant>
  newWorkspaceId?: number
  addressSearchResult: Array<TitleSearchResult>
  selectedAgent: User
  addressDetails?: PhysicalAustralianAddress
}

const initialState: MattersState = {
  status: CreateWorkspaceStatus.initial,
  address: "",
  matches: [],
  users: [],
  addressSearchResult: [],
  selectedAgent: { firstName: "", lastName: "", id: "" },
}

export const createWorkspaceSlice = createAppSlice({
  name: "createWorkspace",
  initialState,
  reducers: {
    initial: state => {
      state.status = CreateWorkspaceStatus.initial
      state.matches = []
      state.users = []
      state.addressSearchResult = []
      state.selectedAddress = undefined
      state.selectedAgent = { firstName: "", lastName: "", id: "" }
      state.selectedTitle = undefined
    },

    changeAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload
    },
    selectedAddress: (
      state,
      action: PayloadAction<FlattenedAddress | undefined>,
    ) => {
      state.selectedAddress = action.payload
    },
    selectedTitle: (
      state,
      action: PayloadAction<TitleSearchResult | undefined>,
    ) => {
      state.selectedTitle = action.payload
    },
    selectedAgent: (state, action: PayloadAction<User | null>) => {
      if (action.payload != null) {
        state.selectedAgent = action.payload
      }
    },
  },
  extraReducers: builder => {
    lookupAddressReducer(builder)
    getAddressReducer(builder)
    getUsersReducer(builder)
    createWorkspaceReducer(builder)
    searchForTitleByAddressReducer(builder)
  },
  selectors: {
    createWorkspaceState: createWorkspace => createWorkspace,
  },
})

function getUsersReducer(builder: ActionReducerMapBuilder<MattersState>) {
  builder.addCase(getTenancyUsers.fulfilled, (state, action) => {
    state.users = action.payload
    const aUser = action.payload[0]
    state.selectedAgent = {
      firstName: aUser.participant.firstName!,
      lastName: aUser.participant.lastName!,
      id: aUser.sub,
    }
    state.status = CreateWorkspaceStatus.ready
  })
}

function searchForTitleByAddressReducer(
  builder: ActionReducerMapBuilder<MattersState>,
) {
  builder.addCase(searchForTitleByAddress.pending, state => {
    state.status = CreateWorkspaceStatus.pending
  })
  builder.addCase(searchForTitleByAddress.fulfilled, (state, action) => {
    state.addressSearchResult = action.payload
    if (action.payload.length === 1) {
      state.selectedTitle = action.payload[0]
    }
    state.status = CreateWorkspaceStatus.ready
  })
}

function createWorkspaceReducer(
  builder: ActionReducerMapBuilder<MattersState>,
) {
  builder.addCase(createWorkspace.fulfilled, (state, action) => {
    state.newWorkspaceId = action.payload
    state.status = CreateWorkspaceStatus.worspaceCreated
  })
  builder.addCase(createWorkspace.pending, state => {
    state.status = CreateWorkspaceStatus.creatingWorkspace
  })
}

function lookupAddressReducer(builder: ActionReducerMapBuilder<MattersState>) {
  builder.addCase(lookupAddress.fulfilled, (state, action) => {
    state.matches = action.payload
    state.status = CreateWorkspaceStatus.ready
  })
}
function getAddressReducer(builder: ActionReducerMapBuilder<MattersState>) {
  builder.addCase(getAddress.pending, state => {
    state.status = CreateWorkspaceStatus.creatingWorkspace
  })
  builder.addCase(getAddress.fulfilled, (state, action) => {
    state.addressDetails = action.payload
    state.status = CreateWorkspaceStatus.ready
  })
}

export const {
  changeAddress,
  initial,
  selectedAddress,
  selectedTitle,
  selectedAgent,
} = createWorkspaceSlice.actions
// export default createWorkspaceSlice

export const { createWorkspaceState } = createWorkspaceSlice.selectors
