import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  Participant1,
  PutMyDetailsRequest,
  TenantContact,
} from "../../landconnex-api-client";
import { teamsApi } from "../../api";

export const updateMyDetails = createAsyncThunk<Participant1, Participant1>(
  "settings/updateMyDetails",
  async (args) => {
    const req: PutMyDetailsRequest = {
      user: args,
    };
    //await manageApi.putMyDetails(req);
    await teamsApi.putMyDetails(req);
    return args;
  }
);

export const updateUserDetails = createAsyncThunk<Participant1, Participant1>(
  "settings/updateUserDetails",
  async (args) => {
    // const req: PutMyDetailsRequest = {
    //   user: args
    // }
    // await manageApi.updateUser(req);
    return args;
  }
);
export const getMyDetails = createAsyncThunk<Participant1, void>(
  "settings/getMyDetails",
  async () => {
    const response = await teamsApi.getMyDetails();
    return response.data.user;
  }
);

export const updatelicenceeDetails = createAsyncThunk<void, TenantContact>(
  "settings/updatelicenceeDetails",
  async (args) => {
    console.log(args);

    //await manageApi.putLicencee(req);
  }
);

export const getLicenceeDetails = createAsyncThunk<TenantContact, void>(
  "settings/getLicenceeDetails",
  async () => {
    // const response = await manageApi.getLicencee();
    return {};
  }
);

export const createPaymentIntent = createAsyncThunk<TenantContact, void>(
  "settings/createPaymentIntent",
  async () => {
    // const response = await manageApi.getLicencee();
    return {};
  }
);
