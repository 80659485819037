import { createAsyncThunk } from "@reduxjs/toolkit";
import { subscriptionsApi, teamsApi } from "../../api";
import {
  CompleteSubscriptionRequest,
  CreateSubscriptionResponse,
  Participant,
  Team,
} from "../../landconnex-api-client";

export const getTeam = createAsyncThunk<Team, void>(
  "settings/getTeam",
  async () => {
    const resp = await teamsApi.getTeam();
    return resp.data;
  }
);

export const startSubscription = createAsyncThunk<
  CreateSubscriptionResponse,
  string
>("settings/startSubscription", async (customerName) => {
  const customer: Participant = {
    organisationName: customerName,
  };
  const resp = await subscriptionsApi.startSubscription(customer);
  return resp.data;
});

export interface CompleteSubscriptionParams {
  setupIntentID: string;
}
export const completeSubscription = createAsyncThunk<
  void,
  CompleteSubscriptionParams
>("settings/completeSubscription", async ({ setupIntentID }) => {
  const params: CompleteSubscriptionRequest = {
    setupIntentID: setupIntentID,
  };

  await subscriptionsApi.completeSubscription(params);
});
